import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function Location() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    const [locations, setLocations] = useState([]);
    const getLocations = () => {
        axios.get(`https://api.vtalentsolution.com/api/getLocations`)
        .then((res) => {
            setLocations(res.data);
        });
    }
    const switchLocation = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to change this Location?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchLocation?locationId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(()=>{
                            getLocations();
                            getLocationSearch();
                        })
                    } else if (result.msg.locationId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.locationId,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [location, setLocation] = useState('');
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createLocation = () => {
        setShow1(true);
    }
    const submitCreateLocation = () => {
        if(location === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter New Location",
            });
        } else {
            axios.post(`https://api.vtalentsolution.com/api/createLocation?location=`+location+``)
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Created Successfully",
                    }).then(() => {
                        setShow1(false);
                        getLocations();
                        getLocationSearch();
                    });
                } else if (res.data.msg.location) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.location,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editLocation = (i, n) => {
        setShow2(true);
        setId(i);
        setLocation(n);
    }
    const submitEditLocation = async () => {
        const data = new URLSearchParams();
        data.append('locationId', id);
        data.append('location', location);
        await axios({
            method: "post",
            url: "https://api.vtalentsolution.com/api/editLocation",
            data: data,
        }).then((res)=>{
            const result = res.data;
            if(result.check === true){
                Toast.fire({
                    icon: "success",
                    title: "Success",
                }).then(() => {
                    setShow2(false);
                    getLocations();
                    getLocationSearch();
                });
            } else if (result.msg.locationId) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.locationId,
                });
            } else if (result.msg.location) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.location,
                });
            }
        })
    }
    const delLocation = async (i) => {
        Swal.fire({
            title: "Do you want to delete this Location?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("locationId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteLocation",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            getLocations();
                            getLocationSearch();
                        });
                    } else if (result.msg.locationId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.locationId,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [locationSearch, setLocationSearch] = useState([]);
    const getLocationSearch = (e) => { 
        axios.get(`https://api.vtalentsolution.com/api/getLocationsSearch?locationSearch=`+e+``)
        .then((res)=>{
            setLocationSearch(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getLocations()
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">LOCATION LIST</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={createLocation}>
                                            <i className="zmdi zmdi-plus mt-2"></i>add location
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                <div className="col-lg-6 col-12">
                                    <input type="text" className="form-control border small" placeholder="Search for..." 
                                    aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getLocationSearch(e.target.value)}/>
                                </div>
                                {
                                     locationSearch.length>0 &&
                                    <div className="col-12 mt-4">
                                        <h6>Search results: {locationSearch.length}</h6>
                                    </div>
                                }
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    locations && locations.length > 0 && locationSearch.length <=0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th className='align-middle'>Location</th>
                                                    <th className='align-middle'>Status</th>

                                                    <th  className='text-center align-middle'>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                locations.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchLocation(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    
                                                    
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editLocation(item.id, item.location)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='mx-2 my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delLocation(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                    
                                                   

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {
                                    locationSearch.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th className='align-middle'>Industry Name</th>
                                                    <th className='align-middle'>Status</th>

                                                    <th className='text-center align-middle'>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                locationSearch.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchLocation(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    
                                                    
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editLocation(item.id, item.location)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='mx-2 my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delLocation(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                   

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }


                                
                               
                                </div>                              
                                
                            </div>
                            
                            
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Create Location */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type="text" className="form-control" placeholder="New Location" onChange={(e) => setLocation(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreateLocation}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Location */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
                <Modal.Title>Update Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input value={location} type="text" className="form-control" onChange={(e) => setLocation(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditLocation}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Location
