import React, { useEffect, useState } from 'react';
import axios from "axios";
import Sidebar from "../Components/Sidebar";
import HeaderMobile from "../Components/HeaderMobile";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import SpinnerS from '../Components/SpinnerS';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
// import { Chart } from 'react-chartjs-2';
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";


function Home() {

    const [totalEarning, setTotalEarning] = useState(0);
    const [totalCV, setTotalCv] = useState(0);
    const [hired, setHired] = useState(0);
    const [pass, setPass] = useState(0);
    const [fail, setFail] = useState(0);
    const [cancelInterview, setCancelInterview] = useState(0);
    const [pending, setPending] = useState(0);
    const [inProcess, setInProcess] = useState(0);
    const [listUserEarning, setListUserEarning] = useState([]);
    const getListUserEarning = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getUserMonthEarning`)
        .then((res)=>{
            setListUserEarning(res.data)
        })
    }
    // console.log(listUserEarning);
    

    const getDataTotalWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getTotalDataWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            setTotalEarning(res.data[0].TotalValue);
            setTotalCv(res.data[0].TotalCV);
        })
    }
    
    // const [report, setReport] = useState({});
    const getReportWithUser = async () => {
        // await axios.get(`https://api.vtalentsolution.com/api/getReportWihtUser?userId=`+localStorage.getItem('id')+``)
        // .then((res)=>{
        //     setReport(res.data);
        // });
        await axios.get(`https://api.vtalentsolution.com/api/getStatusHiredWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setHired(res.data[0].Hired);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getStatusPassWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setPass(res.data[0].Pass);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getStatusFailWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setFail(res.data[0].Fail);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getStatusCancelInterviewWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setCancelInterview(res.data[0].CancelInterview);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getStatusPendingWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setPending(res.data[0].Pending);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getStatusInProcessWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setInProcess(res.data[0].InProcess);
            }
        })

    }


    const [monthEarning, setMonthEarning] = useState(0);
    const [monthCV, setMonthCv] = useState(0);
    const [monthHired, setMonthHired] = useState(0);
    const [monthPass, setMonthPass] = useState(0);
    const [monthFail, setMonthFail] = useState(0);
    const [monthCancelInterview, setMonthCancelInterview] = useState(0);
    const [monthPending, setMonthPending] = useState(0);
    const [monthInProcess, setMonthInProcess] = useState(0);

    const getMonthlyDataWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getMonthlyDataWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            // setDataTotal(res.data);
            setMonthEarning(res.data[0].TotalValue);
            setMonthCv(res.data[0].TotalCV);
        })
    }

    const getMonthReportWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusHiredWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthHired(res.data[0].Hired);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusPassWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthPass(res.data[0].Pass);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusFailWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthFail(res.data[0].Fail);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusCancelInterviewWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthCancelInterview(res.data[0].CancelInterview);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusPendingWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthPending(res.data[0].Pending);
            }
        })
        await axios.get(`https://api.vtalentsolution.com/api/getMonthStatusInProcessWihtUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            if(res.data.length !== 0){
                setMonthInProcess(res.data[0].InProcess);
            }
        })

    }

    // console.log(monthHired, monthFail, monthInProcess, monthPass, monthPending, monthCancelInterview );
    
    
    const [cvList, setCvList] = useState([]);
    const getCvListWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCVsWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            setCvList(res.data);
        })
    }
    // const fisnishCvs = cvList.filter(item => item.status_name === 'Fisnish');
    const percent = Intl.NumberFormat('en-US').format(hired/totalCV*100);
    
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [docs, setDocs] = useState([]);
    const getDocs = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getDocsActive`)
        .then((res)=>{
            setDocs(res.data);
        })
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getCvListWithUser();
            getCvStatus();
            getDocs();
            getDataTotalWithUser();
            getReportWithUser();
            getMonthReportWithUser();
            getMonthlyDataWithUser();
            getListUserEarning();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1">overview</h2>
                                        {/* <button className="au-btn au-btn-icon au-btn--blue">
                                            <i className="zmdi zmdi-plus"></i>add item
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-t-25">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="overview-item overview-item--c1">
                                        <div className="overview__inner">
                                            <div className="overview-box clearfix">
                                                <div className="icon">
                                                    <i className="zmdi zmdi-account-o"></i>
                                                </div>
                                                <div className="text">
                                                    <h2>{hired}</h2>
                                                    <span>CV Complete</span>
                                                </div>
                                            </div>
                                            <div className="overview-chart">
                                                <canvas id="widgetChart1"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="overview-item overview-item--c2">
                                        <div className="overview__inner">
                                            <div className="overview-box clearfix">
                                                <div className="icon">
                                                    {/* <i className="zmdi zmdi-shopping-cart"></i> */}
                                                    <i className="fa-solid fa-circle-check"></i>
                                                </div>
                                                <div className="text">
                                                {
                                                    percent == 'NaN' ?
                                                    <h2>0%</h2>
                                                    :
                                                    <h2>{percent}%</h2>
                                                }
                                                    <span>Success Rate</span>
                                                </div>
                                            </div>
                                            <div className="overview-chart">
                                                <canvas id="widgetChart2"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="overview-item overview-item--c3">
                                        <div className="overview__inner">
                                            <div className="overview-box clearfix">
                                                <div className="icon">
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                                <div className="text">
                                                    <span>Month Earning</span>
                                                    <h2 style={{fontSize: "30px"}}>{Intl.NumberFormat('en-US').format(monthEarning)}</h2>
                                                </div>
                                            </div>
                                            <div className="overview-chart">
                                                <canvas id="widgetChart3"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="overview-item overview-item--c4">
                                        <div className="overview__inner">
                                            <div className="overview-box clearfix">
                                                <div className="icon">
                                                    <i className="zmdi zmdi-money"></i>
                                                    {/* <i class="fa-solid fa-dong-sign fs-5"></i> */}
                                                </div>
                                                <div className="text">
                                                    <span>Total Earnings</span>
                                                    <h2 style={{fontSize: "30px"}}>{Intl.NumberFormat('en-US').format(totalEarning)}</h2>
                                                </div>
                                            </div>
                                            <div className="overview-chart">
                                                <canvas id="widgetChart4"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="au-card recent-report">
                                        <div className="au-card-inner">
                                            <h3 className="title-2">Genaral reports</h3>
                                            <div className="chart-info">
                                                {/* <div className="chart-info__left">
                                                    <div className="chart-note">
                                                        <span className="dot dot--blue"></span>
                                                        <span>products</span>
                                                    </div>
                                                    <div className="chart-note mr-0">
                                                        <span className="dot dot--green"></span>
                                                        <span>services</span>
                                                    </div>
                                                </div> */}
                                                {/* <div className="chart-info__right">
                                                    <div className="chart-statis">
                                                        <span className="index incre">
                                                            <i className="zmdi zmdi-long-arrow-up"></i>25%</span>
                                                        <span className="label">products</span>
                                                    </div>
                                                    <div className="chart-statis mr-0">
                                                        <span className="index decre">
                                                            <i className="zmdi zmdi-long-arrow-down"></i>10%</span>
                                                        <span className="label">services</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="recent-report__chart">
                                                {/* <canvas id="recent-rep-chart"></canvas> */}
                                                
                                                <Doughnut
                                                    data={{
                                                    labels: [
                                                        "In Process",
                                                        "Hired",
                                                        "Pass",
                                                        "Fail",
                                                        "Cancel Interview",
                                                        "Pending"
                                                    ],
                                                    datasets: [
                                                        {
                                                        label: "CV",
                                                        backgroundColor: [
                                                            "#3e95cd",
                                                            "#3cba9f",
                                                            "#e8c3b9",
                                                            "#c45850",
                                                            "#8e5ea2",
                                                            "#c12700"
                                                        ],
                                                       
                                                        // data: [12, 3, 1, 5, 2, 1]
                                                        data: [inProcess, hired, pass, fail, cancelInterview, pending]

                                                        }
                                                    ]
                                                    }}
                                                    option={{
                                                    title: {
                                                        display: true,
                                                        text: "CV processing results"
                                                    }
                                                    }}
                                                />
                                            </div>
                                            <div className='mt-3'>
                                                <span className='mr-4' style={{color: "#000"}}><b>Total CV: {totalCV}</b></span> 
                                                <span className='mr-4' style={{color: "#3e95cd"}}><b>In Process: {inProcess}</b></span> 
                                                <span className='mr-4' style={{color: '#3cba9f'}}><b>Hired: {hired}</b></span>
                                                <span className='mr-4' style={{color: '#e8c3b9'}}><b>Pass: {pass}</b></span>
                                                <span className='mr-4' style={{color: '#c45850'}}><b>Fail: {fail}</b></span>
                                                <span className='mr-4' style={{color: '#8e5ea2'}}><b>Cancel Interview: {cancelInterview}</b></span>
                                                <span className='mr-4' style={{color: '#c12700'}}><b>Pending: {pending}</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="au-card chart-percent-card">
                                        <div className="au-card-inner">
                                            <h3 className="title-2 tm-b-5">Month Report</h3>
                                            <div className="row no-gutters">
                                                {/* <div className="col-xl-6">
                                                    <div className="chart-note-wrap">
                                                        <div className="chart-note mr-0 d-block">
                                                            <span className="dot dot--blue"></span>
                                                            <span>products</span>
                                                        </div>
                                                        <div className="chart-note mr-0 d-block">
                                                            <span className="dot dot--red"></span>
                                                            <span>services</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-xl-12">
                                                    <div className="percent-chart">
                                                        {/* <canvas id="percent-chart"></canvas> */}
                                                        <Bar
                                                            data={{
                                                            labels: [
                                                                "In Process",
                                                                "Hired",
                                                                "Pass",
                                                                "Fail",
                                                                "Cancel Interview",
                                                                "Pending"
                                                            ],
                                                            datasets: [
                                                                {
                                                                label: "CV",
                                                                backgroundColor: [
                                                                    "#3e95cd",
                                                                    "#3cba9f",
                                                                    "#e8c3b9",
                                                                    "#c45850",
                                                                    "#8e5ea2",
                                                                    "#c12700"
                                                                ],
                                                                data: [inProcess, hired, pass, fail, cancelInterview, pending]
                                                                }
                                                            ]
                                                            }}
                                                            options={{
                                                            legend: { display: false },
                                                            title: {
                                                                display: true,
                                                                text: "CV processing results"
                                                            }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <span className='mr-4' style={{color: "#000"}}><b>Total CV: {monthCV}</b></span> 
                                                    <span className='mr-4' style={{color: "#3e95cd"}}><b>In Process: {monthInProcess}</b></span> 
                                                    <span className='mr-4' style={{color: '#3cba9f'}}><b>Hired: {monthHired}</b></span>
                                                    <span className='mr-4' style={{color: '#e8c3b9'}}><b>Pass: {monthPass}</b></span>
                                                    <span className='mr-4' style={{color: '#c45850'}}><b>Fail: {monthFail}</b></span>
                                                    <span className='mr-4' style={{color: '#8e5ea2'}}><b>Cancel Interview: {monthCancelInterview}</b></span>
                                                    <span className='mr-4' style={{color: '#c12700'}}><b>Pending: {monthPending}</b></span>
                                                </div>

                                                {/* <div className="col-xl-12">
                                                <Line
                                                    data={{
                                                    labels: [1500, 1600, 1700, 1750, 1800, 1850, 1900, 1950, 1999, 2050],
                                                    datasets: [
                                                        {
                                                        data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
                                                        label: "Pending",
                                                        borderColor: "#3e95cd",
                                                        fill: false
                                                        },
                                                        {
                                                        data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
                                                        label: "Hired",
                                                        borderColor: "#8e5ea2",
                                                        fill: false
                                                        },
                                                        {
                                                        data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
                                                        label: "Pass",
                                                        borderColor: "#3cba9f",
                                                        fill: false
                                                        },
                                                        {
                                                        data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],
                                                        label: "Cancel Interview",
                                                        borderColor: "#e8c3b9",
                                                        fill: false
                                                        },
                                                        {
                                                        data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],
                                                        label: "Fail",
                                                        borderColor: "#c45850",
                                                        fill: false
                                                        }
                                                    ]
                                                    }}
                                                    options={{
                                                    title: {
                                                        display: true,
                                                        text: "CV processing results"
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: "bottom"
                                                    }
                                                    }}
                                                />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="title-1 m-b-25">CV - Processing</h2>
                                    <div className="table-responsive table--no-card m-b-40" style={{maxHeight: "500px" , overflowY: "auto"}}>
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Candidate Name</th>
                                                    <th>Email</th>
                                                    <th>Staff</th>
                                                    <th>CV status</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                cvList && cvList.length > 0 && cvList.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.fullname}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                        <td className='align-middle'>{item.accountName}</td>
                                                    <td className='align-middle text-warning'><b>{item.status_name}</b></td>
                                                    <td className='align-middle'>
                                                        <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3">
                                    <h2 className="title-1 m-b-25">Top countries</h2>
                                    <div className="au-card au-card--bg-blue au-card-top-countries m-b-40">
                                        <div className="au-card-inner">
                                            <div className="table-responsive">
                                                <table className="table table-top-countries">
                                                    <tbody>
                                                        <tr>
                                                            <td>United States</td>
                                                            <td className="text-right">$119,366.96</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Australia</td>
                                                            <td className="text-right">$70,261.65</td>
                                                        </tr>
                                                        <tr>
                                                            <td>United Kingdom</td>
                                                            <td className="text-right">$46,399.22</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Turkey</td>
                                                            <td className="text-right">$35,364.90</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Germany</td>
                                                            <td className="text-right">$20,366.96</td>
                                                        </tr>
                                                        <tr>
                                                            <td>France</td>
                                                            <td className="text-right">$10,366.96</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Australia</td>
                                                            <td className="text-right">$5,366.96</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Italy</td>
                                                            <td className="text-right">$1639.32</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                
                                <div className="col-lg-4">
                                    <h2 className="title-1 m-b-25">Top Employee List (Month)</h2>
                                    <div className="au-card au-card--bg-blue au-card-top-countries m-b-40">
                                        <div className="au-card-inner">
                                            <div className="table-responsive">
                                                <table className="table table-top-countries">
                                                    <tbody>
                                                        { 
                                                            listUserEarning.length > 0 && listUserEarning.map((item, index)=>(
                                                            <tr key={index}>
                                                                <td>{item.accountName}</td>
                                                                <td className="text-right">{Intl.NumberFormat('en-US').format(item.TotalValue)} đ</td>
                                                            </tr>
                                                            ))
                                                        }
                                                        { 
                                                            listUserEarning.length <= 0 && 
                                                            <tr>
                                                                <td>Staff Name</td>
                                                                <td className="text-right">đ-----</td>
                                                            </tr>
                                                        }
                                                       
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <h2 className="title-1 m-b-25">Document training</h2>
                                    <div className="table-responsive table--no-card m-b-40" style={{maxHeight: "500px", minHeight: "400px" , overflowY: "auto"}}>
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Document Name</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                docs && docs.length > 0 && docs.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.file_name}</td>
                                                    
                                                    <td className='align-middle'>
                                                        {/* <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a> */}
                                                        <a href={`https://api.vtalentsolution.com/File/`+item.file_name+``} download={item.file_name} target="_blank" className='text-center text-decoration-none d-block'>
                                                            <i className="fa-solid fa-print text-black" style={{ fontSize: "1.8rem" }}></i>
                                                        </a>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-lg-6">
                                    <div className="au-card au-card--no-shadow au-card--no-pad m-b-40">
                                        <div className="au-card-title" style={{backgroundImage: "url('images/bg-title-01.jpg')"}}>
                                            <div className="bg-overlay bg-overlay--blue"></div>
                                            <h3>
                                                <i className="zmdi zmdi-account-calendar"></i>26 April, 2018</h3>
                                            <button className="au-btn-plus">
                                                <i className="zmdi zmdi-plus"></i>
                                            </button>
                                        </div>
                                        <div className="au-task js-list-load">
                                            <div className="au-task__title">
                                                <p>Tasks for John Doe</p>
                                            </div>
                                            <div className="au-task-list js-scrollbar3">
                                                <div className="au-task__item au-task__item--danger">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Meeting about plan for Admin Template 2018</a>
                                                        </h5>
                                                        <span className="time">10:00 AM</span>
                                                    </div>
                                                </div>
                                                <div className="au-task__item au-task__item--warning">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Create new task for Dashboard</a>
                                                        </h5>
                                                        <span className="time">11:00 AM</span>
                                                    </div>
                                                </div>
                                                <div className="au-task__item au-task__item--primary">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Meeting about plan for Admin Template 2018</a>
                                                        </h5>
                                                        <span className="time">02:00 PM</span>
                                                    </div>
                                                </div>
                                                <div className="au-task__item au-task__item--success">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Create new task for Dashboard</a>
                                                        </h5>
                                                        <span className="time">03:30 PM</span>
                                                    </div>
                                                </div>
                                                <div className="au-task__item au-task__item--danger js-load-item">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Meeting about plan for Admin Template 2018</a>
                                                        </h5>
                                                        <span className="time">10:00 AM</span>
                                                    </div>
                                                </div>
                                                <div className="au-task__item au-task__item--warning js-load-item">
                                                    <div className="au-task__item-inner">
                                                        <h5 className="task">
                                                            <a href="#">Create new task for Dashboard</a>
                                                        </h5>
                                                        <span className="time">11:00 AM</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="au-task__footer">
                                                <button className="au-btn au-btn-load js-load-btn">load more</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="au-card au-card--no-shadow au-card--no-pad m-b-40">
                                        <div className="au-card-title"  style={{backgroundImage: "url('images/bg-title-02.jpg')"}}>
                                            <div className="bg-overlay bg-overlay--blue"></div>
                                            <h3>
                                                <i className="zmdi zmdi-comment-text"></i>New Messages</h3>
                                            <button className="au-btn-plus">
                                                <i className="zmdi zmdi-plus"></i>
                                            </button>
                                        </div>
                                        <div className="au-inbox-wrap js-inbox-wrap">
                                            <div className="au-message js-list-load">
                                                <div className="au-message__noti">
                                                    <p>You Have
                                                        <span>2</span>

                                                        new messages
                                                    </p>
                                                </div>
                                                <div className="au-message-list">
                                                    <div className="au-message__item unread">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-02.jpg" alt="John Smith"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">John Smith</h5>
                                                                    <p>Have sent a photo</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>12 Min ago</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="au-message__item unread">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap online">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-03.jpg" alt="Nicholas Martinez"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">Nicholas Martinez</h5>
                                                                    <p>You are now connected on message</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>11:00 PM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="au-message__item">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap online">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-04.jpg" alt="Michelle Sims"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">Michelle Sims</h5>
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>Yesterday</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="au-message__item">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-05.jpg" alt="Michelle Sims"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">Michelle Sims</h5>
                                                                    <p>Purus feugiat finibus</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>Sunday</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="au-message__item js-load-item">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap online">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-04.jpg" alt="Michelle Sims"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">Michelle Sims</h5>
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>Yesterday</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="au-message__item js-load-item">
                                                        <div className="au-message__item-inner">
                                                            <div className="au-message__item-text">
                                                                <div className="avatar-wrap">
                                                                    <div className="avatar">
                                                                        <img src="images/icon/avatar-05.jpg" alt="Michelle Sims"/>
                                                                    </div>
                                                                </div>
                                                                <div className="text">
                                                                    <h5 className="name">Michelle Sims</h5>
                                                                    <p>Purus feugiat finibus</p>
                                                                </div>
                                                            </div>
                                                            <div className="au-message__item-time">
                                                                <span>Sunday</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="au-message__footer">
                                                    <button className="au-btn au-btn-load js-load-btn">load more</button>
                                                </div>
                                            </div>
                                            <div className="au-chat">
                                                <div className="au-chat__title">
                                                    <div className="au-chat-info">
                                                        <div className="avatar-wrap online">
                                                            <div className="avatar avatar--small">
                                                                <img src="images/icon/avatar-02.jpg" alt="John Smith"/>
                                                            </div>
                                                        </div>
                                                        <span className="nick">
                                                            <a href="#">John Smith</a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="au-chat__content">
                                                    <div className="recei-mess-wrap">
                                                        <span className="mess-time">12 Min ago</span>
                                                        <div className="recei-mess__inner">
                                                            <div className="avatar avatar--tiny">
                                                                <img src="images/icon/avatar-02.jpg" alt="John Smith"/>
                                                            </div>
                                                            <div className="recei-mess-list">
                                                                <div className="recei-mess">Lorem ipsum dolor sit amet, consectetur adipiscing elit non iaculis</div>
                                                                <div className="recei-mess">Donec tempor, sapien ac viverra</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="send-mess-wrap">
                                                        <span className="mess-time">30 Sec ago</span>
                                                        <div className="send-mess__inner">
                                                            <div className="send-mess-list">
                                                                <div className="send-mess">Lorem ipsum dolor sit amet, consectetur adipiscing elit non iaculis</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="au-chat-textfield">
                                                    <form className="au-form-icon">
                                                        <input className="au-input au-input--full au-input--h65" type="text" placeholder="Type a message"/>
                                                        <button className="au-input-icon">
                                                            <i className="zmdi zmdi-camera"></i>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>

        </div>
    </>
    )
}

export default Home
