import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { format } from 'date-fns';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';
import Spinner from "../Components/Spinner";

function Users() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [role, setRole] = useState([]);   
    const [id, setId] = useState(0);
    const getRole = () => {
        fetch("https://api.vtalentsolution.com/api/getRoles")
        .then((res) => res.json())
        .then((res) => {
            setRole(res);
        });
    };
    const [show5, setShow5] = useState(false);

    const [number, setNumber]= useState([]);
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const getUsers = () => {
        axios.get(`https://api.vtalentsolution.com/api/getUser?page=`+page+``)
        .then((res) => {
            setUsers(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old)
        });
    }
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [roleUser, setRoleUser] = useState("");

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const showModalCreateUser = (e) => {
        setShow2(true);
    };
    const submitCreateUser = async (e) => {
        e.preventDefault();
        if (userName === "" || email === "" || roleUser == 0) {
            Toast.fire({
                icon: "warning",
                title: "Enter complete information, please!",
            });
        } else {
            setShow5(true)
            var data = new URLSearchParams();
            data.append("name", userName);
            data.append("email", email);
            data.append("idRole", roleUser);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createUser",
                data: data,
            }).then((res) => {
                setShow5(false);
                if (res.data.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow2(false);
                        getUsers();
                    });
                } else if (res.data.msg.name) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.name,
                    });
                } else if (res.data.msg.email) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.email,
                    });
                } else if (res.data.msg.idRole) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.idRole,
                    });
                }
            });
        }
    };
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const showModalEditUser = (id, userNameOld) => {
        setShow3(true);
        setId(id);
        setUserName(userNameOld);
    };
    const submitEditUser = async (e) => {
        e.preventDefault();
        if (userName === "") {
            Toast.fire({
                icon: "warning",
                title: "Enter Username",
            });
        } else {
            var data = new URLSearchParams();
            data.append("id", id);
            data.append("name", userName);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editUserName",
                data: data,
            }).then((res) => {
                if (res.data.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow3(false);
                        getUsers();
                    });
                } else if (res.data.msg.name) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.name,
                    });
                } else if (res.data.msg.id) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.id,
                    });
                }
            });
        }
    };
    const switchUser = (id) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to change the status of this account?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", id);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/switchUser",
                    data: data, 
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(() => {
                            getUsers();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const showModalEditRoleUser = (id, n) => {
        setShow4(true);
        setId(id);
    };
    const submitEditRoleUser = async (e) => {
        e.preventDefault();
        var data = new URLSearchParams();
        data.append("id", id);
        data.append('idRole', roleUser)
        axios({
            method: "post",
            url: "https://api.vtalentsolution.com/api/editRoleUser",
            data: data,
        }).then((res) => {
            const result = res.data;
            if (result.check === true) {
                Toast.fire({
                    icon: "success",
                    title: "Success",
                }).then(() => {
                    getUsers();
                    setShow4(false);
                });
            } else if (result.msg.id    ) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            }else if (result.msg.idRole) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.idRole,
                });
            }
        });
    }
    const deleteUser = (e) =>{
        Swal.fire({
            title: "Do you want to delete this account?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = new FormData();
                data.append('id', e);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/delUser",
                    data: data,
                }).then((res) => {
                    // console.log(res.data);
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            if(res.data.result.last_page >= page){
                                getUsers();
                            }else{
                                setPage(res.data.result.last_page-1);
                                getUsers();
                            }
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }

    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getRole();
        }else{
            window.location.replace('/');
        }
    }, []);
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token') && localStorage.getItem('role') == 4){
            axios.get(`https://api.vtalentsolution.com/api/getUser?page=`+page+``)
            .then((res) => {
                setUsers(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }
    }, [page]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">MANAGER USERS</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue mt-2" onClick={showModalCreateUser}>
                                            <i className="zmdi zmdi-plus"></i>add user</button>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    users && users.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>Email</th>
                                                    <th>Permission</th>
                                                    <th>Status</th>
                                                    <th>Create At</th>
                                                    <th className="text-center">Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((item, index)=>(
                                                <tr key={index}>
                                                    <td onClick={() => showModalEditUser(item.id, item.name)} style={{ cursor: "pointer" }}><b>{item.name}</b></td>
                                                    <td>{item.email}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={()=>showModalEditRoleUser(item.id, item.rolename)}>
                                                        <b>{item.rolename}</b>
                                                    </td>
                                                    <td className='align-middle' onClick={() => switchUser(item.id)}  style={{ cursor: "pointer" }}>
                                                        {
                                                            item.status === 1 ? 
                                                                <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                            : 
                                                                <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                        }
                                                    </td>
                                                    <td>{format(item.created_at, 'do MMMM  yyyy, h:mm:ss a')}</td>
                                                    <td className="text-center">
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={() => deleteUser(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>                              
                                
                            </div>
                            <div className="d-flex text-center">
                                <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                    <ul className="pagination">
                                    {
                                        number && number.length>0 && number.map((item,index)=>(
                                            page == item ?
                                                <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                    <span className="page-link">{item}</span>
                                                </li>
                                            :
                                                <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                    <span className="page-link">{item}</span>
                                                </li>
                                        ))
                                    }
                                    </ul>
                                </nav>
                            </div> 
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>

        </div>

        {/* Modal Create User */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
                <Modal.Title>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    id="username"
                    className="form-control"
                    placeholder="Username"
                    onChange={(e) => setUserName(e.target.value)}
                />
                <input
                    type="email"
                    id="email"
                    className="form-control mt-3"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-3">
                    <label htmlFor="role" className="me-3">
                    <h6>Select Permission:</h6>
                    </label>
                    <select
                        name="role"
                        id="role"
                        className="form-control"
                        onChange={(e) => setRoleUser(e.target.value)}
                    >
                        <option value="0">Select Permission</option>
                    {
                        role && role.length > 0 && role.map((item, index) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))};
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreateUser}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal Edit User */}
        <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
                <Modal.Title>Update Username</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={userName}
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    onChange={(e) => setUserName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditUser}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal Edit User Role */}
        <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
                <Modal.Title>Change user permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="role" className="me-3">
                    <h6>Permission:</h6>
                </label>
                <select name="role" id="editRole" className="form-control" onChange={(e) => setRoleUser(e.target.value)}>
                    <option value="0">Select permission</option>
                {
                    role && role.length > 0 && role.map((item, index) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))};
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose4}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditRoleUser}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Users
