import React from 'react';
import Swal from "sweetalert2";

function Sidebar() {
    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 1500,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //     toast.addEventListener("mouseenter", Swal.stopTimer);
    //     toast.addEventListener("mouseleave", Swal.resumeTimer);
    //     },
    // });
    // const checkLogout = (e) => {
    //     Swal.fire({
    //         icon:'question',
    //         text: "Bạn muốn đăng xuất khỏi tài khoản?",
    //         showDenyButton: true,
    //         showCancelButton: false,
    //         confirmButtonText: "Có",
    //         denyButtonText: `Không`,
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             if(localStorage.getItem('email') && localStorage.getItem('token')){
    //                 localStorage.removeItem('email');
    //                 localStorage.removeItem('name');
    //                 localStorage.removeItem('token');
    //                 localStorage.removeItem('id');
    //                 localStorage.removeItem('role');
    //                 window.location.replace('/');
    //             }else{
    //                 window.location.replace('/');
    //             }  
    //         } else if (result.isDenied) {
    //         }
    //     })  
    // }
    return (
    <>
        {/* <!-- MENU SIDEBAR--> */}
        <aside className="menu-sidebar d-none d-lg-block">
            <div className="logo">
                <a href="/dashboard">
                    {/* <img src="images/icon/logo.png" alt="Cool Admin" /> */}
                    <img src="../images/vtalent logo.png" alt="Vtalent Solution" />
                </a>
            </div>
            <div className="menu-sidebar__content js-scrollbar1">
                <nav className="navbar-sidebar">
                    <ul className="list-unstyled navbar__list">
                        <li className="has-sub">
                            <a className="js-arrow" href="/dashboard">
                                <i className="fas fa-tachometer-alt"></i>Dashboard</a>
                            {/* <ul className="list-unstyled navbar__sub-list js-sub-list">
                                <li>
                                    <a href="index.html">Dashboard 1</a>
                                </li>
                                <li>
                                    <a href="index2.html">Dashboard 2</a>
                                </li>
                                <li>
                                    <a href="index3.html">Dashboard 3</a>
                                </li>
                                <li>
                                    <a href="index4.html">Dashboard 4</a>
                                </li>
                            </ul> */}
                        </li>
                    {
                        localStorage.getItem('role') == 4 &&
                        <li>
                            <a href="/permission">
                                <i className="fa-solid fa-people-roof"></i>Permission</a>
                        </li>
                    }
                    {
                        localStorage.getItem('role') == 4 &&
                        <li>
                            <a href="/users">
                                <i className="fa-solid fa-user"></i>Users</a>
                        </li>
                    }
                    {
                        localStorage.getItem('role') >= 4 &&
                        <li>
                            <a href="/report">
                                <i className="fa-solid fa-flag"></i>Reports</a>
                        </li>
                    }
                        <li>
                            <a href="/cv-list">
                                <i className="fa-solid fa-id-card"></i>CV List</a>
                        </li>
                        <li>
                            <a href="/job-list">
                                <i className="fa-solid fa-briefcase"></i>Jobs List</a>
                        </li>
                        <li>
                            <a href="/industry">
                                <i className="fa-solid fa-industry"></i>Industries List</a>
                        </li>
                        <li>
                            <a href="/location">
                                <i className="fa-solid fa-location-crosshairs"></i>Locations List</a>
                        </li>
                        <li>
                            <a href="/company-partner">
                                <i className="fa-solid fa-handshake"></i>Company Partner</a>
                        </li>
                        <li>
                            <a href="/blog">
                                <i className="fa-solid fa-blog"></i>Blog</a>
                        </li>
                    {
                        localStorage.getItem('role') >= 3 &&
                        <li>
                            <a href="/document-training">
                                <i className="fa-solid fa-folder-open"></i>Training</a>
                        </li>
                    }
                        <li>
                            <a href="/history">
                                <i className="fa-solid fa-clock-rotate-left"></i>History</a>
                        </li>
                        
                        
                       
                    </ul>
                </nav>
            </div>
        </aside>
        {/* <!-- END MENU SIDEBAR--> */}
    </>
    )
}

export default Sidebar
