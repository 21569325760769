import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function History() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [listHistory, setListHistory]= useState([]);
    const getListHistory = async () => {
        await axios.get('https://api.vtalentsolution.com/api/getAllJobCv')
        .then((res)=>{
            setListHistory(res.data);
        })
    }
    const [jobName, setJobName] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [fullName, setFullName] = useState('');
    const [historySearch, setHistorySearch] = useState([]);
    const getSearch = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getHistory?jobName=`+jobName+`&fullName=`+fullName+`&locationId=`+locationId+`&industryId=`+industryId+``)
        .then((res)=>{
            setHistorySearch(res.data);
        })
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getListHistory();
            getLocationList();
            getIndustryList();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1">CV processing history</h2>
                                        
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className='col-lg-12 border rounded bg-light'>
                                    <h3 className='text-left py-3'>Search</h3>
                                    <div className="row">
                                        <div className='col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control" placeholder="Job Name" onChange={(e)=>setJobName(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control" placeholder="Candidate Name" onChange={(e)=>setFullName(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <select className='w-100 form-control border' onChange={(e)=>setLocationId(e.target.value)}>
                                                        <option value="0" >Select Location</option>
                                                    {
                                                        locationList && locationList.length > 0 && locationList.map((item, index)=>(
                                                            <option key={index} value={item.id}>{item.location}</option>
                                                        ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <select className='w-100 form-control border' onChange={(e)=>setIndustryId(e.target.value)}>
                                                        <option value="0">Select Industry</option>
                                                    {
                                                        industryList && industryList.length > 0 && industryList.map((item, index)=>(
                                                            <option key={index} value={item.id}>{item.industry}</option>
                                                        ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* <div className='col-12'>
                                        {
                                            jobsSearch.length>0 &&
                                            <div className="col-12 mt-4">
                                                <h6>Kết quả tìm kiếm: {jobsSearch.length}</h6>
                                            </div>
                                        }
                                        </div> */}
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className='col-lg-2 col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group ">
                                                    {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                    <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>getSearch()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>           
                            </div>
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    listHistory && listHistory.length >= 0 && historySearch.length <= 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-bordered table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white text-center">
                                                    <th colSpan={4}>JOB</th>
                                                    <th colSpan={4}>CV</th>
                                                </tr>
                                                <tr className='table-secondary'>
                                                    <th className='align-middle'>Job Name</th>
                                                    <th className='align-middle'>Salary</th>
                                                    <th className='align-middle'>Industry</th>
                                                    <th className='align-middle'>Location</th>
                                                    <th className='align-middle'>Candidate Name</th>
                                                    <th className='align-middle'>Email</th>
                                                    <th className='align-middle'>File CV</th>
                                                    <th className='align-middle'>New File CV</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                listHistory.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.JobName}</td>
                                                    <td className='align-middle'>{item.Salary}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle text-capitalize'>{item.fullName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {/* <td className='align-middle'>{item.phone}</td> */}
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" download={item.fileCv} className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                
                                {
                                    historySearch.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-bordered table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white text-center">
                                                    <th colSpan={4}>JOB</th>
                                                    <th colSpan={4}>CV</th>
                                                </tr>
                                                <tr className='table-secondary'>
                                                    <th className='align-middle'>Job Name</th>
                                                    <th className='align-middle'>Salary</th>
                                                    <th className='align-middle'>Industry</th>
                                                    <th className='align-middle'>Location</th>
                                                    <th className='align-middle'>Candidate Name</th>
                                                    <th className='align-middle'>Email</th>
                                                    <th className='align-middle'>File CV</th>
                                                    <th className='align-middle'>New File CV</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                historySearch.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.JobName}</td>
                                                    <td className='align-middle'>{item.Salary}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle text-capitalize'>{item.fullName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {/* <td className='align-middle'>{item.phone}</td> */}
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" download={item.fileCv} className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                
                                </div>    
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>
    </>
    )
}

export default History
