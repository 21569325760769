import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Permission from "./Pages/Permission";
import Blog from "./Pages/Blog";
import CV from "./Pages/CV";
import DocumentTraining from "./Pages/DocumentTraning";
import History from "./Pages/History";
import Industry from "./Pages/Industtry";
import Jobs from "./Pages/Jobs";
import Location from "./Pages/Location";
import Partner from "./Pages/Partner";
import Users from "./Pages/Users";
import Login from "./Pages/Login";
import DetailJob from "./Pages/DetailJob";
import DetailCV from "./Pages/DetailCV";
import Report from "./Pages/Report";

function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
              
                {
                    localStorage.getItem('role') == 4 &&
                    <Route path="/permission" element={<Permission/>} />
                }
                {
                    localStorage.getItem('role') == 4 &&
                    <Route path="/users" element={<Users/>} />
                }
                {
                    localStorage.getItem('role') >= 4 &&
                    <Route path="/report" element={<Report/>} />
                }
                    <Route path="/" element={<Login/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/dashboard" element={<Home/>} />
                    <Route path="/job-list" element={<Jobs/>} />
                    <Route path="/industry" element={<Industry/>} />
                    <Route path="/company-partner" element={<Partner/>} />
                    <Route path="/cv-list" element={<CV/>} />
                    <Route path="/location" element={<Location/>} />
                    <Route path="/blog" element={<Blog/>} />
                    <Route path="/detail-job/:id" element={<DetailJob/>} />
                    <Route path="/detail-cv/:id" element={<DetailCV/>} />
                    <Route path="/document-training" element={<DocumentTraining/>} />
                    <Route path="/history" element={<History/>} />
                  
              </Routes>
          </BrowserRouter>
      </div>
  );
}

export default App;
