import React from 'react'

function Footer() {
    return (
    <>
        <footer className="row mt-5">
            <div className="col-md-12">
                <div className="copyright">
                <span>Copyright &copy; V Talent Solution</span>
                </div>
            </div>
        </footer>
    </>
    )
}

export default Footer
