import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import 'react-quill-new/dist/quill.bubble.css';
import 'react-quill-new/dist/quill.core.css';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function Jobs() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [companyList, setCompanyList] = useState([]);
    const getCompanyList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getPartnersOn`)
        .then((res)=> {            
            setCompanyList(res.data);
        })
    }
    
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [showAdd, setShowAdd] = useState(true);
    const [id, setId] = useState(0);
    const [showJobs, setShowJobs] = useState(true)
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [number, setNumber] = useState([])
    const getJobs = ()=> {
        axios.get(`https://api.vtalentsolution.com/api/getJobsPage?page=`+page+``)
        .then((res) => {
            setJobs(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old);
        });
    }
    
    const [jobName, setJobName] = useState('');
    const [salary, setSalary] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [companyId, setCompanyId] = useState(null)
    const [jobDescription ,setJobDescription] = useState('');
    const [jobRequirements, setJobRequirements] = useState('');
    const [jobBenefits, setJobBenefits]= useState('');
    // const [requirementList, setRequirementList] = useState([{requirement: ""}]);
    // const addModuleRequirement = () => {
    //     setRequirementList([...requirementList, {requirement: ""}]);
    // }
    // const addRequirement = (value, index) => {
    //     const list = [...requirementList];
    //     list[index].requirement = value;
    // }
    // const delRequirement = (i) => {
    //     const list = [...requirementList];
    //     list.splice(i,1);
    //     setRequirementList(list);
    // }
    // const createJob = () => {
    //     setShowJobs(false);
    // }
    
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        setJobName('');
        setSalary('');
        setLocationId(0);
        setLocation('');
        setIndustryId(0);
        setIndustryName('');
        setJobDescription('');
        setJobRequirements('');
        setJobBenefits('');
        setCompanyId(null);
    }
    const createJob = () => {
        setShow2(true);
    }
    // const cancelCreateJob = () => {
    //     setShowJobs(true);
    //     setJobName('');
    //     setSalary('');
    //     setLocationId(0);
    //     setLocation('');
    //     setIndustryId(0);
    //     setIndustryName('');
    //     setJobDescription('');
    //     setJobRequirements('');
    //     setJobBenefits('')
    //     // setRequirementList([{requirement: ""}]);
    // }
    const submitCreateJob = async () => {
        // const requir = JSON.stringify(requirementList);
        if(jobName === '' && salary === '' && locationId === 0 && industryId === 0){
            Toast.fire({
                icon: "warning",
                title: 'Please enter information !',
            });
        } else {
            const data = new URLSearchParams();
            data.append('jobName', jobName);
            data.append('salary', salary);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('companyId', companyId);
            data.append('jobRequirements', jobRequirements);
            data.append('jobDescription', jobDescription);
            data.append('jobBenefits', jobBenefits);
            data.append('companyId', companyId);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/createJob',
                data: data
            }).then((res)=> {
                console.log(res.data);
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Created Successfully",
                    }).then(()=>{
                        getJobs();
                        // setShowJobs(true);
                        setShow2(false);
                        setJobName('');
                        setSalary('');
                        setLocationId(0);
                        setIndustryId(0);
                        setJobDescription('');
                        setJobRequirements('');
                        setJobBenefits('');
                        setCompanyId(null);
                        // setRequirementList([{requirement: ""}]);
                        // searchJobs();
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.salary){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.salary,
                    });
                } else if (result.msg.locationId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.locationId,
                    });
                } else if (result.msg.industryId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.industryId,
                    });
                }
            })
        }
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false)
    const editJobName = (i, n) => {
        setShow1(true);
        setJobName(n);
        setId(i);
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false)
    const [location, setLocation] = useState('');
    const [industryName, setIndustryName] = useState('');
    const [companyPartner, setCompanyPartner] = useState('');
    const editJob = async (i) => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJob?id=`+i+``)
        .then((res)=>{
            console.log(res.data[0]);
            
            setId(i);
            setShow3(true);
            setShowAdd(false);
            setJobName(res.data[0].job_name);
            setSalary(res.data[0].salary);
            setLocationId(res.data[0].location_id);
            setLocation(res.data[0].location);
            setIndustryId(res.data[0].industry_id);
            setIndustryName(res.data[0].industryName);
            setJobDescription(res.data[0].job_description);
            setJobRequirements(res.data[0].job_requirements);
            setJobBenefits(res.data[0].job_benefits)
            setCompanyId(res.data[0].company_id);
            setCompanyPartner(res.data[0].company_name);
            // setRequirementList(JSON.parse(res.data[0].job_requirement_list));
        })
    }
    const submitEditJob = async () => {
        if(jobName === '' && salary === '' && locationId === 0 && industryId === 0){
            Toast.fire({
                icon: "warning",
                title: "'Please enter information !'",
            });
        } else {
            const data = new URLSearchParams();
            data.append('id', id);
            data.append('jobName', jobName);
            data.append('salary', salary);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('jobRequirements', jobRequirements);
            data.append('jobDescription', jobDescription);
            data.append('jobBenefits', jobBenefits);
            data.append('companyId', companyId);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/editJob',
                data: data
            }).then((res)=> {
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(()=>{
                        getJobs();
                        setId(0);
                        // setShowJobs(true);
                        setShow3(false);
                        setShowAdd(true);
                        setJobName('');
                        setSalary('');
                        setLocationId(0);
                        setIndustryId(0);
                        setJobDescription('');
                        setJobRequirements('');
                        setJobBenefits('');
                        setCompanyId(null);
                        // setRequirementList([{requirement: ""}]);
                        // searchJobs();
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.salary){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.salary,
                    });
                } else if (result.msg.locationId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.locationId,
                    });
                } else if (result.msg.industryId){
                    Toast.fire({
                        icon: "error",  
                        title: result.msg.industryId,
                    });
                }
            })
        }
    }
    const submitEditJobName = async (e) => {
        e.preventDefault();
        if(jobName === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter Job name",
            });
        } else {
            var data = new URLSearchParams();
            data.append('id', id);
            data.append('jobName', jobName);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/editJobName',
                data: data,
            }).then((res) => {
                const result = res.data;
                console.log(res);
                if (result.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(()=>{
                        getJobs();
                        setJobName('');
                        setShow1(false);
                        // searchJobs();
                        // setId(0);
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.id){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.id,
                    });
                } 
            })
        }
    }
    const switchJob = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to change this Job status?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchJob?id=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(()=>{
                            getJobs();
                            // searchJobs();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const delJob = (i) => {
        console.log(i);
        Swal.fire({
            title: "Do you want to delete this Job?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteJob",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(() => {
                            getJobs();
                            // searchJobs();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [keyWord, setKeyWord] = useState('');
    const [jobsSearch, setJobsSearch] = useState([]);
    const searchJobs = () => {
        axios(`https://api.vtalentsolution.com/api/getJobsSearch?keyWord=`+keyWord+`&locationId=`+locationId+`&industryId=`+industryId+``)
        .then((res)=> {
            const result = res.data;
            setJobsSearch(result);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getJobs();
            getLocationList();
            getIndustryList();
            getCompanyList();
        }else{
            window.location.replace('/');
        }  
    }, []);
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            axios.get(`https://api.vtalentsolution.com/api/getJobsPage?page=`+page+``)
            .then((res) => {
                setJobs(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }  
    }, [page]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">JOB LIST</h2>
                                        {/* {
                                            showJobs ?
                                            <button className="au-btn au-btn-icon au-btn--blue" onClick={createJob}>
                                                <i className="zmdi zmdi-plus"></i>add JOB
                                            </button>
                                        :
                                            <button className="au-btn au-btn-icon btn-warning" onClick={cancelCreateJob}>
                                                <i className="fa-solid fa-ban fa-sm text-white-50 me-2"></i>add JOB
                                            </button>
                                        } */}
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={createJob}>
                                                <i className="zmdi zmdi-plus mt-2"></i>ADD JOB
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {
                                showJobs &&
                                <div className="row">
                                    <div className='col-lg-12 border rounded'>
                                        <h3 className='text-left py-3'>Search</h3>
                                        <div className="row">
                                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                                <div className="mb-3">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control form-control" placeholder="Keyword" onChange={(e)=>setKeyWord(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className="mb-3">
                                                    <div className="input-group">
                                                        <select className='w-100 form-control border' onChange={(e)=>setLocationId(e.target.value)}>
                                                            <option value="0" >Select location</option>
                                                        {
                                                            locationList && locationList.length > 0 && locationList.map((item, index)=>(
                                                                <option key={index} value={item.id}>{item.location}</option>
                                                            ))
                                                        }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className="mb-3">
                                                    <div className="input-group">
                                                        <select className='w-100 form-control border' onChange={(e)=>setIndustryId(e.target.value)}>
                                                            <option value="0">Industry</option>
                                                        {
                                                            industryList && industryList.length > 0 && industryList.map((item, index)=>(
                                                                <option key={index} value={item.id}>{item.industry}</option>
                                                            ))
                                                        }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-1 col-md-6 col-sm-12'>
                                                <div className="mb-3">
                                                    <div className="input-group ">
                                                        {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                        <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>searchJobs()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                            {
                                                jobsSearch.length>0 &&
                                                <div className="col-12 mt-4">
                                                    <h6>Search results: {jobsSearch.length}</h6>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>           
                                </div>
                            }
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    showJobs && jobs && jobs.length > 0 && jobsSearch.length <=0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th className='align-middle'>Job name</th>
                                                    <th className='align-middle'>Salary</th>
                                                    <th className='align-middle'>Location</th>
                                                    <th className='align-middle'>Industry</th>
                                                    <th className='align-middle'>Status</th>
                                                    <th className='align-middle'>Quantity CV Apply for</th>
                                                    <th colSpan="3" className='text-center align-middle'>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                jobs.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle text-capitalize' onClick={()=>editJobName(item.id, item.job_name)} style={{ cursor: "pointer" }}>{item.job_name}</td>
                                                    <td className='align-middle'>{item.salary}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchJob(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{item.SL}</td>
                                                    <td className='text-center align-middle'>
                                                        {/* <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editJob(item.id, item.salary, item.location, item.industry, item.requirements)}  style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        <a href={"/detail-job/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                        
                                                        
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square secondary" onClick={()=>editJob(item.id)} style={{ cursor:"pointer"}}></i></span>
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash danger" onClick={()=>delJob(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }


                                {
                                    showJobs && jobsSearch.length >0 &&
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Job name</th>
                                                    <th>Salary</th>
                                                    <th>Location</th>
                                                    <th>Industry</th>
                                                    <th>Status</th>
                                                    <th>Quantity CV Apply for</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                jobsSearch.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize' onClick={()=>editJobName(item.id, item.job_name)}>{item.job_name}</td>
                                                    <td className='align-middle'>{item.salary}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchJob(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{item.SL}</td>
                                                    <td className='align-middle'>
                                                        {/* <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editJob(item.id, item.salary, item.location, item.industry, item.requirements)}  style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        <a href={"/detail-job/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square secondary" onClick={()=>editJob(item.id)} style={{ cursor:"pointer"}}></i></span>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash danger" onClick={()=>delJob(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }
                               
                                </div>                              
                                
                            </div>
                            {
                                showJobs && jobsSearch.length <=0 &&
                                    <div className="d-flex">
                                    <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                        <ul className="pagination">
                                        {
                                            number && number.length>0 && number.map((item,index)=>(
                                                page == item ?
                                                    <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                                :
                                                    <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                            ))
                                        }
                                        </ul>
                                    </nav>
                                </div> 
                            }
                            
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Edit Job Name */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Update Job Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={jobName}
                    type="text"
                    className="form-control"
                    onChange={(e) => setJobName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditJobName}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        
        {/* Modal Create Job */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create New Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                {/* <h3 className="text-center mb-5"><b>Tạo công việc</b></h3> */}
                <div className="row  align-items-center g-2">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Job Name : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={jobName} onChange={(e) => setJobName(e.target.value)} placeholder="Job Name"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Salary : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={salary} onChange={(e) => setSalary(e.target.value)} placeholder="Salary"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Location : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                            <option value={locationId}>{location}</option>
                        {
                            locationList && locationList.length > 0 && locationList.map((item, index) => (
                                <option key={index} value={item.id}>{item.location}</option>
                            ))
                        } 
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Industry : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                            <option value={industryId}>{industryName}</option>
                        {
                            industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.industry}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 col-md-12 mt-4">
                        <label htmlFor="nameCourse"><b>Customer : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setCompanyId(e.target.value)}>
                            <option value={companyId}>{companyPartner}</option>
                        {
                            companyList && companyList.length > 0 && companyList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.company_name}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 mt-4">
                        <label htmlFor="" className='mb-1'><b>Job Description</b></label>
                        <ReactQuill
                            value={jobDescription}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobDescription}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Job Requirements</b></label>
                        <ReactQuill
                            value={jobRequirements}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobRequirements}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Benefits</b></label>
                        <ReactQuill
                            value={jobBenefits}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobBenefits}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreateJob}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Job */}
        <Modal show={show3} onHide={handleClose3} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                {/* <h3 className="text-center mb-5"><b>Tạo công việc</b></h3> */}
                <div className="row  align-items-center g-2">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Job Name : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={jobName} onChange={(e) => setJobName(e.target.value)} placeholder="Job Name"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Salary : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={salary} onChange={(e) => setSalary(e.target.value)} placeholder="Salary"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Location : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                            <option value={locationId}>{location}</option>
                        {
                            locationList && locationList.length > 0 && locationList.map((item, index) => (
                                <option key={index} value={item.id}>{item.location}</option>
                            ))
                        } 
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Industry : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                            <option value={industryId}>{industryName}</option>
                        {
                            industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.industry}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 col-md-12 mt-4">
                        <label htmlFor="nameCourse"><b>Customer : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setCompanyId(e.target.value)}>
                            <option value={companyId}>{companyPartner}</option>
                        {
                            companyList && companyList.length > 0 && companyList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.company_name}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 mt-4">
                        <label htmlFor="" className='mb-1'><b>Job Description</b></label>
                        <ReactQuill
                            value={jobDescription}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobDescription}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Job Requirements</b></label>
                        <ReactQuill
                            value={jobRequirements}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobRequirements}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Benefits</b></label>
                        <ReactQuill
                            value={jobBenefits}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobBenefits}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditJob}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Jobs
