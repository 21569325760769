import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function CV() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [userList, setUserList] = useState();
    const getUserList = async () => {
        axios.get(`https://api.vtalentsolution.com/api/getUsersOn`)
        .then((res)=>{
            setUserList(res.data);
        })
    }
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [cvStatusNot1, setCvStatusNot1] = useState([]);
    const getCvStatusNot1 = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatusNot1?id=`)
        .then((res)=>{
            setCvStatusNot1(res.data);
        })
    }
    const [disableBtn, setDisableBtn] = useState(false);
    const [userId, setUserId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [number, setNumber]= useState([]);
    const [page, setPage] = useState(1);
    const [listCV, setListCV] = useState([]);
    const getListCV = () => {
        axios.get(`https://api.vtalentsolution.com/api/getCVsPage?page=`+page+``)
        .then((res) => {
            setListCV(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old);
        });
    }
    const selectCV = (i) =>{
        Swal.fire({
            title: "Do you choose to process this CV ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("cvId", i);
                data.append("email", localStorage.getItem('email'));
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/selectCV",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(() => {
                            getListCV();
                            setSearchCvs([]);
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [keyWord, setKeyWord] = useState('');
    const [searchCvs, setSearchCvs] = useState([]);
    const getSearchCVs = () => {
        axios(`https://api.vtalentsolution.com/api/getCvsSearch?keyWord=`+keyWord+`&userId=`+userId+`&statusId=`+statusId+``)
        .then((res)=> {
            const result = res.data;
            setSearchCvs(result);
        })
    }
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.openxmlformats-officedocument.wordprocessingml.docume'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const addCv = () => {
        setShow1(true);
    }
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    // const [jobId, setJobId] = useState(0);
    const [recentJob, setRecentJob] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [note, setNote] = useState('');
    const formatPhoneNumber = /(0[3|5|7|8|9]+([0-9]{8}))\b/g;  

    const submitAddCv = () => {
        setDisableBtn(true);
        if(
            fullName === '' && email === '' && phone === ''){
            Toast.fire({
                icon: 'error',
                title: 'Please enter information !'
            });
        } else if(!phone.match(formatPhoneNumber)) {
            Toast.fire({
                icon: 'error',
                title: 'Your phone number format is incorrect !'
            });
        // } else if (file === null) {
        //     Toast.fire({
        //         icon: 'error',
        //         title: 'Select your CV file, Please !!!'
        //     });
        } else {
            var data = new FormData();
            data.append('fullName', fullName);
            data.append('email', email);
            data.append('phone', phone);
            data.append('recentJob', recentJob);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('fileCV', file);
            data.append('note', note);
            data.append('userId', localStorage.getItem('id'));
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/addCv",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Add Successfully'
                    }).then(()=>{
                        setShow1();
                        getListCV();
                        setFullName('');
                        setEmail('');
                        setPhone('');
                        setRecentJob('');
                        setLocationId(0);
                        setIndustryId(0);
                        // setJobId(0);
                        setNote('');
                        setIndustryName('');
                        setLocation('');
                        setSearchCvs([]);
                        setDisableBtn(false);
                    })
                }else if(res.data.msg.fullName){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.fullName
                    });
                    setDisableBtn(false);
                }else if(res.data.msg.email){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.email
                    });
                    setDisableBtn(false);
                }else if(res.data.msg.phone){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.phone
                    });
                    setDisableBtn(false);
                }else if(res.data.msg.locationId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.locationId
                    });
                    setDisableBtn(false);
                }else if(res.data.msg.industryId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.industryId
                    });
                    setDisableBtn(false);
                }else if(res.data.msg.userId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.userId
                    });
                    setDisableBtn(false);
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                    setDisableBtn(false);
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        setFullName('');
        setEmail('');
        setPhone('');
        setRecentJob('');
        setLocationId(0);
        setIndustryId(0);
        setNote('');
        setIndustryName('');
        setLocation('');
    }

    const [idCV, setIdCV] = useState(0);
    const [location, setLocation] = useState('');
    const [industryName, setIndustryName] = useState('');
    const editCV = async (i) => {
        setShow2(true);
        await axios.get(`https://api.vtalentsolution.com/api/getSingleCv?id=`+i+``)
        .then((res)=>{
            console.log(res.data[0]);
            setIdCV(i);
            setFullName(res.data[0].fullname);
            setEmail(res.data[0].email);
            setPhone(res.data[0].phone);
            setRecentJob(res.data[0].recent_job);
            setIndustryId(res.data[0].industryId);
            setIndustryName(res.data[0].industryName);
            setLocationId(res.data[0].locationId);
            setLocation(res.data[0].location);
            setNote(res.data[0].note);

            
            // setRequirementList(JSON.parse(res.data[0].job_requirement_list));
        })
    }
    const submitEditCv = () => {
        if(
            fullName === '' && email === '' && phone === ''){
            Toast.fire({
                icon: 'error',
                title: 'Please enter information !!'
            });
        } else if(!phone.match(formatPhoneNumber)) {
            Toast.fire({
                icon: 'error',
                title: 'Your phone number format is incorrect !!'
            });
        } else {
            var data = new FormData();
            data.append('cvId', idCV);
            data.append('fullName', fullName);
            data.append('email', email);
            data.append('phone', phone);
            data.append('recentJob', recentJob);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('note', note);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editCv",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Success'
                    }).then(()=>{
                        setShow2(false);
                        getListCV();
                        setFullName('');
                        setEmail('');
                        setPhone('');
                        setRecentJob('');
                        setLocationId(0);
                        setIndustryId(0);
                        setNote('');
                        setIndustryName('');
                        setLocation('');
                        setSearchCvs([]);
                    })
                }else if(res.data.msg.fullName){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.fullName
                    });
                }else if(res.data.msg.email){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.email
                    });
                }else if(res.data.msg.phone){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.phone
                    });
                }else if(res.data.msg.locationId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.locationId
                    });
                }else if(res.data.msg.industryId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.industryId
                    });
                }else if(res.data.msg.userId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.userId
                    });
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                }
            })
        }
    }
    const removeUser = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to delete this employee from this CV ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/removeUser?cvId=`+i+``)
                .then((res)=>{
                    const result = res.data;
                    if(result.check === true){
                        Toast.fire({
                            icon: 'success',
                            title: 'Deleted Successfully'
                        }).then(()=>{
                            getListCV();
                            setSearchCvs([]);
                        })
                    }else if (result.msg.cvId){
                        Toast.fire({
                            icon: 'error',
                            title: result.msg.cvId
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        }) 
    }

    const delCV = (i) => {
        console.log(i);
        Swal.fire({
            title: "Do you want to delete this CV?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("cvId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteCV",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            getListCV();
                            setSearchCvs([]);
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [cvId, setCvId] = useState(0);
    const [status, setStatus] = useState('');
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {
        setShow3(false);
        setCvId(0);
        setStatusId(0);
        setStatus("");
    }
    const changeStatus = (i, n, s) => {
        setCvId(i);
        setStatusId(s);
        setStatus(n);
        setShow3(true);
        
    }
    const submitChangeStatus = async () => {
        const userId = localStorage.getItem('id');
        const data = new FormData();
        data.append('id', cvId);
        data.append('statusId', statusId);
        data.append('userId', userId);
        await axios({
            method: 'post',
            url: 'https://api.vtalentsolution.com/api/changeStatus',
            data: data,
        }).then((res)=> {
            const result = res.data;
            if(result.check === true){
                Toast.fire({
                    icon: "success",
                    title: "Success",
                }).then(()=>{
                    setCvId(0);
                    setStatusId(0);
                    setStatus('');
                    setShow3(false);
                    getListCV();
                })
            } else if (result.msg.id){
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            } else if (result.msg.statusId){
                Toast.fire({
                    icon: "error",
                    title: result.msg.statusId,
                });
            }
        })
    }
    // console.log(listCV);
    // console.log(statusId);
    
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getListCV();
            getUserList();
            getCvStatus();
            getCvStatusNot1();
            getIndustryList();
            getLocationList();
        }else{
            window.location.replace('/');
        }  
    },[]);
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            axios.get(`https://api.vtalentsolution.com/api/getCVsPage?page=`+page+``)
            .then((res) => {
                setListCV(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }  
    },[page]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">CV LIST</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={addCv}>
                                            <i className="zmdi zmdi-plus mt-2"></i>add CV</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className='col-lg-12 borderless rounded'>
                                   
                                    <div className="row justify-content-between">
                                        <h3 className='text-left py-3 col-md-6'>Search</h3>
                                        <a href="/history" className='text-right py-3 col-md-6 text-decoration-none'><b>CV Processing History</b></a>
                                    </div>
                                    
                                    <div className="row">
                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control" placeholder="Candidate name" onChange={(e)=>setKeyWord(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <select className='w-100 form-control border' onChange={(e)=>setUserId(e.target.value)}>
                                                        <option value="0" >Employee</option>
                                                    {
                                                        userList && userList.length > 0 && userList.map((item, index)=>(
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <select className='w-100 form-control border' onChange={(e)=>setStatusId(e.target.value)}>
                                                        <option value="0" >CV Status</option>
                                                    {
                                                        cvStatus && cvStatus.length > 0 && cvStatus.map((item, index)=>(
                                                            <option key={index} value={item.id}>{item.status_name}</option>
                                                        ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-6 col-sm-12'>
                                            <div className="mb-3">
                                                <div className="input-group ">
                                                    {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                    <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>getSearchCVs()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                        {
                                            searchCvs.length>0 &&
                                            <div className="col-12 mt-4">
                                                <h6>Search results: {searchCvs.length}</h6>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>           
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    listCV && listCV.length > 0 && searchCvs.length<=0 &&
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Candidate Name</th>
                                                    <th>Email</th>
                                                    <th>Staff</th>
                                                    <th>CV status</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listCV.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.fullname}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {
                                                        item.accountName == null ?
                                                        <td className="align-middle">
                                                            {/* <span className='me-2 my-1 fs-4 rounded-circle border border-primary'><i className="fa-solid fa-hand-pointer text-primary" style={{ cursor:"pointer"}}></i></span> */}
                                                            <button className="btn btn-primary" onClick={()=>selectCV(item.id)}><i className="fa-solid fa-hand-pointer"></i></button>
                                                        </td>
                                                        :
                                                        <td className='align-middle'>{item.accountName}</td>
                                                    }
                                                    
                                                    {/* {
                                                        (localStorage.getItem('role') == 4 || item.user_id == localStorage.getItem('id')) ?
                                                        <td className='align-middle text-warning' style={{ cursor:"pointer"}} onClick={()=>changeStatus(item.id, item.status_name, item.status)}>
                                                            <b>{item.status_name}</b>
                                                        </td>
                                                        :
                                                        <td className='align-middle text-warning'>
                                                            <b>{item.status_name}</b>
                                                        </td>
                                                    } */}
                                                    {
                                                        (localStorage.getItem('role') == 4 || item.user_id == localStorage.getItem('id')) ?
                                                        <td className='align-middle' style={{ cursor:"pointer"}} onClick={()=>changeStatus(item.id, item.status_name, item.status)}>
                                                            {
                                                                item.status_name == 'Direct Applicant' ?
                                                                <b className="text-primary">{item.status_name}</b>
                                                                : item.status_name == 'Onboarded' ? 
                                                                <b className="text-success">{item.status_name}</b>
                                                                : (item.status_name == 'Stop' || item.status_name == 'Drop Out' || item.status_name == 'Withdrawn' || item.status_name == 'Rejected by Account Holder' || item.status_name == 'Rejected by Client' || item.status_name == 'Offer Declined' || item.status_name == 'Replaced') ?
                                                                <b className="text-danger">{item.status_name}</b>
                                                                :
                                                                <b className="text-warning">{item.status_name}</b>
                                                            }
                                                        </td>
                                                        :
                                                        <td className='align-middle'>
                                                            {
                                                                item.status_name == 'Direct Applicant' ?
                                                                <b className="text-primary">{item.status_name}</b>
                                                                : item.status_name == 'Onboarded' ? 
                                                                <b className="text-success">{item.status_name}</b>
                                                                : (item.status_name == 'Stop' || item.status_name == 'Drop Out' || item.status_name == 'Withdrawn' || item.status_name == 'Rejected by Account Holder' || item.status_name == 'Rejected by Client' || item.status_name == 'Offer Declined' || item.status_name == 'Replaced') ?
                                                                <b className="text-danger">{item.status_name}</b>
                                                                :
                                                                <b className="text-warning">{item.status_name}</b>
                                                            }
                                                        </td>
                                                    }

                                                    {/* <td className='align-middle'>
                                                    {
                                                        (localStorage.getItem('role') == 4 || item.user_id == localStorage.getItem('id')) ?
                                                        <i className="fa-solid fa-pen-to-square mr-2" style={{ cursor:"pointer", color: "grey" }} onClick={()=>changeStatus(item.id, item.status_name, item.status)}></i>
                                                        :
                                                        <span></span>

                                                    }
                                                    {
                                                        item.status_name == 'Direct Applicant' ?
                                                        <b className="text-primary">{item.status_name}</b>
                                                        : item.status_name == 'Onboarded' ? 
                                                        <b className="text-success">{item.status_name}</b>
                                                        : (item.status_name == 'Stop' || item.status_name == 'Drop Out' || item.status_name == 'Withdrawn' || item.status_name == 'Rejected by Account Holder' || item.status_name == 'Rejected by Client' || item.status_name == 'Offer Declined' || item.status_name == 'Replaced') ?
                                                        <b className="text-danger">{item.status_name}</b>
                                                        :
                                                        <b className="text-warning">{item.status_name}</b>
                                                    }
                                                    </td> */}

                                                    <td className='align-middle'>
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }} onClick={()=>editCV(item.id)}></i></span>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }} onClick={()=>editCV(item.id)}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        
                                                        {
                                                            localStorage.getItem('role') == 4 ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>delCV(item.id)}><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-warning" style={{ cursor:"pointer"}}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {
                                    searchCvs.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Candidate Name</th>
                                                    <th>Email</th>
                                                    <th>Staff</th>
                                                    <th>CV status</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                searchCvs.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.fullname}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {
                                                        item.accountName == null ?
                                                        <td className="align-middle">
                                                            {/* <span className='me-2 my-1 fs-4 rounded-circle border border-primary'><i className="fa-solid fa-hand-pointer text-primary" style={{ cursor:"pointer"}}></i></span> */}
                                                            <button className="btn btn-primary" onClick={()=>selectCV(item.id)}><i className="fa-solid fa-hand-pointer"></i></button>
                                                        </td>
                                                        :
                                                        <td className='align-middle'>{item.accountName}</td>
                                                    }
                                                    {/* {
                                                        (localStorage.getItem('role') == 4 || item.user_id == localStorage.getItem('id')) ?
                                                        <td className='align-middle text-warning' style={{ cursor:"pointer"}} onClick={()=>changeStatus(item.id, item.status_name, item.status)}><b>{item.status_name}</b></td>
                                                        :
                                                        <td className='align-middle text-warning'><b>{item.status_name}</b></td>
                                                    } */}
                                                    {
                                                        (localStorage.getItem('role') == 4 || item.user_id == localStorage.getItem('id')) ?
                                                        <td className='align-middle' style={{ cursor:"pointer"}} onClick={()=>changeStatus(item.id, item.status_name, item.status)}>
                                                            {
                                                                item.status_name == 'Direct Applicant' ?
                                                                <b className="text-primary">{item.status_name}</b>
                                                                : item.status_name == 'Onboarded' ? 
                                                                <b className="text-success">{item.status_name}</b>
                                                                : (item.status_name == 'Stop' || item.status_name == 'Drop Out' || item.status_name == 'Withdrawn' || item.status_name == 'Rejected by Account Holder' || item.status_name == 'Rejected by Client' || item.status_name == 'Offer Declined' || item.status_name == 'Replaced') ?
                                                                <b className="text-danger">{item.status_name}</b>
                                                                :
                                                                <b className="text-warning">{item.status_name}</b>
                                                            }
                                                        </td>
                                                        :
                                                        <td className='align-middle'>
                                                            {
                                                                item.status_name == 'Direct Applicant' ?
                                                                <b className="text-primary">{item.status_name}</b>
                                                                : item.status_name == 'Onboarded' ? 
                                                                <b className="text-success">{item.status_name}</b>
                                                                : (item.status_name == 'Stop' || item.status_name == 'Drop Out' || item.status_name == 'Withdrawn' || item.status_name == 'Rejected by Account Holder' || item.status_name == 'Rejected by Client' || item.status_name == 'Offer Declined' || item.status_name == 'Replaced') ?
                                                                <b className="text-danger">{item.status_name}</b>
                                                                :
                                                                <b className="text-warning">{item.status_name}</b>
                                                            }
                                                        </td>
                                                    }

                                                    <td className=' align-middle'>
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }} onClick={()=>editCV(item.id)}></i></span>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }} onClick={()=>editCV(item.id)}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        
                                                        {
                                                            localStorage.getItem('role') == 4 ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>delCV(item.id)}><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-warning" style={{ cursor:"pointer"}}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>                              
                                
                            </div>
                            {
                                searchCvs.length<=0 &&
                                <div className="d-flex">
                                    <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                        <ul className="pagination">
                                        {
                                            number && number.length>0 && number.map((item,index)=>(
                                                page == item ?
                                                    <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                                :
                                                    <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                            ))
                                        }
                                        </ul>
                                    </nav>
                                </div> 
                            }
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Add CV */}
        <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add CV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="card-body">
                    <div className="row  align-items-center g-2">
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Candidate Name: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Candidate Name"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Email: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Phone: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Recent Job:</b></label>
                            <input type="text" className='form-control mt-1' value={recentJob} onChange={(e) => setRecentJob(e.target.value)} placeholder="Recent Job"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Desired Workplace: <span style={{color: "red"}}>*</span></b></label>
                            <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                                <option value='0'>Select Location</option>
                                
                            {
                                locationList && locationList.length > 0 && locationList.map((item, index) => (
                                    <option key={index} value={item.id}>{item.location}</option>
                                ))
                            } 
                            </select>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Industry: <span style={{color: "red"}}>*</span></b></label>
                            <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                                <option value='0'>Select Industry</option>
                            {
                                industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                    <option key={index} value={item.id}>{item.industry}</option>
                                ))
                            }
                            </select>
                        </div>
                        
                        <div className="col-12 mt-4 mb-4">
                            <label htmlFor="" className='mb-1'><b>Note</b></label>
                            <ReactQuill
                                value={note}
                                theme='snow'
                                multiple = {false}
                                onChange={setNote}
                                modules={{ 
                                    toolbar : [
                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                        ['blockquote', 'code-block'],
                                        
                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                        [{ 'direction': 'rtl' }],                         // text direction
                                        
                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                        [{ 'font': [] }],
                                        [{ 'align': [] }],
                                        ['link', 'image'],
                                        
                                        ['clean']                                         // remove formatting button
                                    ]
                                }}
                            />
                        </div>
                    </div>                    
                    
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                {
                    disableBtn ?
                    <Button variant="secondary">
                        Save
                    </Button>
                    :
                    <Button variant="primary" onClick={submitAddCv}>
                        Save
                    </Button>
                }
                    
                </Modal.Footer>
        </Modal>

        {/* Modal Edit CV */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit CV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                <div className="row  align-items-center g-2">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Candidate Name: <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Candidate Name"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Email: <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Phone: <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Recent Job:</b></label>
                        <input type="text" className='form-control mt-1' value={recentJob} onChange={(e) => setRecentJob(e.target.value)} placeholder="Recent Job"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Desired Workplace: <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                        {
                            locationId == 0 || locationId == null?
                            <option value='0'>Select Location</option>
                            :
                            <option value={locationId}>{location}</option>
                        }
                            
                        {
                            locationList && locationList.length > 0 && locationList.map((item, index) => (
                                <option key={index} value={item.id}>{item.location}</option>
                            ))
                        } 
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Industry: <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                            {
                            industryId == 0 || industryId == null?
                            <option value='0'>Select Industry</option>
                            :
                            <option value={industryId}>{industryName}</option>
                        }
                        {
                            industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.industry}</option>
                            ))
                        }
                        </select>
                    </div>
                    
                    {/* <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Ghi chú</b></label>
                        <ReactQuill
                            value={note}
                            theme='snow'
                            multiple = {false}
                            onChange={setNote}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div> */}
                </div>                    
                
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditCv}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Change Status Modal */}
        <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
                <Modal.Title>Change CV Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <label htmlFor="status"><b>Select CV Status <span style={{color: "red"}}>*</span></b></label>
                    <select name="" id="status" className="form-control mt-1" onChange={(e)=> setStatusId(e.target.value)}>
                        <option value={statusId}>{status}</option>
                    {
                        cvStatusNot1 && cvStatusNot1.length > 0 && cvStatusNot1.map((item, index)=> (
                            <option key={index} value={item.id}>{item.status_name}</option>
                        ))
                    }
                    </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitChangeStatus}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default CV
