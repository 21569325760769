import React, { useEffect, useState } from 'react';
import axios from "axios";
import Sidebar from "../Components/Sidebar";
import HeaderMobile from "../Components/HeaderMobile";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Report() {
    const [listUserEarning, setListUserEarning] = useState([]);
    const getListUserEarning = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getUserMonthEarning`)
        .then((res)=>{
            setListUserEarning(res.data)
        })
    }
    const [totalData, setTotalData] = useState([])
    const getDataTotalWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getTotalData`)
        .then((res)=>{
            setTotalData(res.data);
        })
    }
    console.log(listUserEarning);
    console.log(totalData);
    
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getDataTotalWithUser();
            getListUserEarning();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                        <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">MONTH REPORT</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Employee</th>
                                                    <th>Email</th>
                                                    <th>Total CV</th>
                                                    <th>Total Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listUserEarning.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.accountName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    <td className='align-middle'>{item.TotalCV}</td>
                                                    <td className='align-middle'>{Intl.NumberFormat('en-US').format(item.TotalValue)}</td>
                                                </tr>
                                                ))
                                            }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                              
                                
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">GENERAL REPORT</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Employee</th>
                                                    <th>Email</th>
                                                    <th>Total CV</th>
                                                    <th>Total Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                totalData.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='align-middle text-capitalize'>{item.accountName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    <td className='align-middle'>{item.TotalCV}</td>
                                                    <td className='align-middle'>{Intl.NumberFormat('en-US').format(item.TotalValue)}</td>
                                                </tr>
                                                ))
                                            }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                              
                                
                            </div>
                           
                           
                            <Footer/>
                        </div>

                        
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>
    </>
    )
}

export default Report
