import React, { useEffect, useState } from "react";

function HeaderMobile() {
    const [tog, onTog] = useState(false);

    return (
    <>
        {/* <!-- HEADER MOBILE--> */}
        <header className="header-mobile d-block d-lg-none">
            <div className="header-mobile__bar">
                <div className="container-fluid">
                    <div className="header-mobile-inner">
                        <a className="logo" href="/dashboard">
                            {/* <img src="images/icon/logo.png" alt="CoolAdmin" /> */}
                            <img src="../images/vtalent logo.png" alt="Vtalent Solution" style={{height:"60px"}}/>
                        </a>
                    {
                        !tog ?
                        <button className="hamburger hamburger--slider" onClick={()=>onTog(true)}>
                            <span className={!tog ?  "hamburger-box" : "hamburger-box d-none"}>
                                <span className= {!tog ?  "hamburger-inner" : "hamburger-inner d-none"}></span>
                            </span>
                        </button>
                        :
                        <button className="hamburger hamburger--slider" onClick={()=>onTog(false)}>
                            <span className={tog ?  "d-block" : "d-none"}>
                                <i class="fa-solid fa-xmark"></i>
                            </span>
                        </button>
                    }
                    </div>
                </div>
            </div>
            <nav className= {tog ?  "navbar-mobile d-block" : "navbar-mobile d-none"}>
                <div className="container-fluid">
                    <ul className="navbar-mobile__list list-unstyled">
                        <li className="has-sub">
                            <a className="js-arrow" href="/dashboard">
                                <i className="fas fa-tachometer-alt"></i>Dashboard</a>
                            {/* <ul className="navbar-mobile-sub__list list-unstyled js-sub-list">
                                <li>
                                    <a href="index.html">Dashboard 1</a>
                                </li>
                                <li>
                                    <a href="index2.html">Dashboard 2</a>
                                </li>
                                <li>
                                    <a href="index3.html">Dashboard 3</a>
                                </li>
                                <li>
                                    <a href="index4.html">Dashboard 4</a>
                                </li>
                            </ul> */}
                        </li>
                        {
                        localStorage.getItem('role') == 4 &&
                        <li>
                            <a href="/permission">
                                <i className="fa-solid fa-people-roof"></i>Permission</a>
                        </li>
                    }
                    {
                        localStorage.getItem('role') == 4 &&
                        <li>
                            <a href="/users">
                                <i className="fa-solid fa-user"></i>Users</a>
                        </li>
                    }
                        <li>
                            <a href="/cv-list">
                                <i className="fa-solid fa-id-card"></i>CV List</a>
                        </li>
                        <li>
                            <a href="/job-list">
                                <i className="fa-solid fa-briefcase"></i>Jobs List</a>
                        </li>
                        <li>
                            <a href="/industry">
                                <i className="fa-solid fa-industry"></i>Industries List</a>
                        </li>
                        <li>
                            <a href="/location">
                                <i className="fa-solid fa-location-crosshairs"></i>Locations List</a>
                        </li>
                        <li>
                            <a href="/company-partner">
                                <i className="fa-solid fa-handshake"></i>Company Partner</a>
                        </li>
                        <li>
                            <a href="/blog">
                                <i className="fa-solid fa-blog"></i>Blog</a>
                        </li>
                    {
                        localStorage.getItem('role') >= 3 &&
                        <li>
                            <a href="/document-training">
                                <i className="fa-solid fa-folder-open"></i>Training</a>
                        </li>
                    }
                        <li>
                            <a href="/history">
                                <i className="fa-solid fa-clock-rotate-left"></i>History</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        {/* <!-- END HEADER MOBILE--> */}
    </>
    )
}

export default HeaderMobile
