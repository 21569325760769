import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Header() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const checkLogout = (e) => {
        Swal.fire({
            icon:'question',
            text: "Log Out?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                if(localStorage.getItem('email') && localStorage.getItem('token')){
                    localStorage.removeItem('email');
                    localStorage.removeItem('name');
                    localStorage.removeItem('token');
                    localStorage.removeItem('id');
                    localStorage.removeItem('role');
                    window.location.replace('/');
                }else{
                    window.location.replace('/');
                }  
            } else if (result.isDenied) {
            }
        })  
    }
    const [oldPass, setOldPass] = useState('');
    const [pass, setPass] = useState('');
    const [pass1, setPass1] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const showModalChangePass = () => {
        setShow(true);
    }
    const submitChangePass = (e) => { 
        e.preventDefault();
        if(pass == '' || pass1 == ''){
            Toast.fire({
                icon: "error",
                title: "Enter Password"
            });
        }else if( pass != pass1){
            Toast.fire({
                icon: "error",
                title: "wrong password"
            });
        }else{
            const email = localStorage.getItem('email')
            // console.log(oldPass);
            // const data = new FormData();
            // data.append('email', email);
            // data.append('oldPass', oldPass);
            // data.append('password', pass);
            // data.append('password1', pass1);
            // axios.post('https://api.vtalentsolution.com/api/changePassword', data)
            // axios({
            //     method: "post",
            //     url: "https://api.vtalentsolution.com/api/changePassword",
            //     data: data,
            //     headers: { "Content-Type": "multipart/form-data" },
            // })
            axios.post(`https://api.vtalentsolution.com/api/changePassword?email=`+email+`&oldPass=`+oldPass+`&password=`+pass+`&password1=`+pass1+``)
            .then((res)=>{
                if(res.data.check == true){
                    Toast.fire({
                        icon: "success",
                        title: "Success"
                    }).then(()=>{
                        localStorage.removeItem('email');
                        localStorage.removeItem('name');
                        localStorage.removeItem('token');
                        localStorage.removeItem('id');
                        localStorage.removeItem('role');
                        window.location.replace('/');
                    })
                }else if(res.data.msg.email){
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.email,
                    });
                }else if(res.data.msg.password){
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.password,
                    });
                }else if(res.data.msg.oldPass){
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.oldPass,
                    });
                }else if(res.data.msg.password1){
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.password1,
                    });
                }else if(res.data.msg){
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg,
                    });
                }
            });
        }
    }
    return (
    <>
        <header className="header-desktop">
            <div className="section__content section__content--p30">
                <div className="container-fluid">
                    <div className="header-wrap">
                        <form className="form-header" action="" method="POST">
                            <input className="au-input au-input--xl" type="text" name="search" placeholder="Search for datas &amp; reports..." />
                            <button className="au-btn--submit" type="submit">
                                <i className="zmdi zmdi-search"></i>
                            </button>
                        </form>
                        <div className="header-button">
                            {/* <div className="noti-wrap">
                                <div className="noti__item js-item-menu">
                                    <i className="zmdi zmdi-comment-more"></i>
                                    <span className="quantity">1</span>
                                    <div className="mess-dropdown js-dropdown">
                                        <div className="mess__title">
                                            <p>You have 2 news message</p>
                                        </div>
                                        <div className="mess__item">
                                            <div className="image img-cir img-40">
                                                <img src="images/icon/avatar-06.jpg" alt="Michelle Moreno" />
                                            </div>
                                            <div className="content">
                                                <h6>Michelle Moreno</h6>
                                                <p>Have sent a photo</p>
                                                <span className="time">3 min ago</span>
                                            </div>
                                        </div>
                                        <div className="mess__item">
                                            <div className="image img-cir img-40">
                                                <img src="images/icon/avatar-04.jpg" alt="Diane Myers" />
                                            </div>
                                            <div className="content">
                                                <h6>Diane Myers</h6>
                                                <p>You are now connected on message</p>
                                                <span className="time">Yesterday</span>
                                            </div>
                                        </div>
                                        <div className="mess__footer">
                                            <a href="#">View all messages</a>
                                        </div>
                                    </div> 
                                </div>
                                <div className="noti__item js-item-menu">
                                    <i className="zmdi zmdi-email"></i>
                                    <span className="quantity">1</span>
                                    <div className="email-dropdown js-dropdown">
                                        <div className="email__title">
                                            <p>You have 3 New Emails</p>
                                        </div>
                                        <div className="email__item">
                                            <div className="image img-cir img-40">
                                                <img src="images/icon/avatar-06.jpg" alt="Cynthia Harvey" />
                                            </div>
                                            <div className="content">
                                                <p>Meeting about new dashboard...</p>
                                                <span>Cynthia Harvey, 3 min ago</span>
                                            </div>
                                        </div>
                                        <div className="email__item">
                                            <div className="image img-cir img-40">
                                                <img src="images/icon/avatar-05.jpg" alt="Cynthia Harvey" />
                                            </div>
                                            <div className="content">
                                                <p>Meeting about new dashboard...</p>
                                                <span>Cynthia Harvey, Yesterday</span>
                                            </div>
                                        </div>
                                        <div className="email__item">
                                            <div className="image img-cir img-40">
                                                <img src="images/icon/avatar-04.jpg" alt="Cynthia Harvey" />
                                            </div>
                                            <div className="content">
                                                <p>Meeting about new dashboard...</p>
                                                <span>Cynthia Harvey, April 12,,2018</span>
                                            </div>
                                        </div>
                                        <div className="email__footer">
                                            <a href="#">See all emails</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="noti__item js-item-menu">
                                    <i className="zmdi zmdi-notifications"></i>
                                    <span className="quantity">3</span>
                                    <div className="notifi-dropdown js-dropdown">
                                        <div className="notifi__title">
                                            <p>You have 3 Notifications</p>
                                        </div>
                                        <div className="notifi__item">
                                            <div className="bg-c1 img-cir img-40">
                                                <i className="zmdi zmdi-email-open"></i>
                                            </div>
                                            <div className="content">
                                                <p>You got a email notification</p>
                                                <span className="date">April 12, 2018 06:50</span>
                                            </div>
                                        </div>
                                        <div className="notifi__item">
                                            <div className="bg-c2 img-cir img-40">
                                                <i className="zmdi zmdi-account-box"></i>
                                            </div>
                                            <div className="content">
                                                <p>Your account has been blocked</p>
                                                <span className="date">April 12, 2018 06:50</span>
                                            </div>
                                        </div>
                                        <div className="notifi__item">
                                            <div className="bg-c3 img-cir img-40">
                                                <i className="zmdi zmdi-file-text"></i>
                                            </div>
                                            <div className="content">
                                                <p>You got a new file</p>
                                                <span className="date">April 12, 2018 06:50</span>
                                            </div>
                                        </div>
                                        <div className="notifi__footer">
                                            <a href="#">All notifications</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="account-wrap">
                                {/* <div className="account-item clearfix js-item-menu">
                                    <div className="image">
                                        <img src="images/icon/avatar-01.jpg" alt="John Doe" />
                                    </div>
                                    <div className="content">
                                        <a className="js-acc-btn" href="#">john doe</a>
                                    </div>
                                    <div className="account-dropdown js-dropdown">
                                        <div className="info clearfix">
                                            <div className="image">
                                                <a href="#">
                                                    <img src="images/icon/avatar-01.jpg" alt="John Doe" />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h5 className="name">
                                                    <a href="#">john doe</a>
                                                </h5>
                                                <span className="email">johndoe@example.com</span>
                                            </div>
                                        </div>
                                        <div className="account-dropdown__body">
                                            <div className="account-dropdown__item">
                                                <a href="#">
                                                    <i className="zmdi zmdi-account"></i>Account</a>
                                            </div>
                                            <div className="account-dropdown__item">
                                                <a href="#">
                                                    <i className="zmdi zmdi-settings"></i>Setting</a>
                                            </div>
                                            <div className="account-dropdown__item">
                                                <a href="#">
                                                    <i className="zmdi zmdi-money-box"></i>Billing</a>
                                            </div>
                                        </div>
                                        <div className="account-dropdown__footer">
                                            <a href="#">
                                                <i className="zmdi zmdi-power"></i>Logout</a>
                                        </div>
                                    </div>
                                </div> */}

                                 {/* <div className="nav-item dropdown no-arrow account-item clearfix">
                                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline text-black bold"><b>{localStorage.getItem('name')}</b></span>
                                    </a>
                                    
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">s
                                        
                                        <a className="dropdown-item" href="/user-profile">
                                            <i className="fa-solid fa-address-card mr-2 text-gray-400"></i>
                                            Profile
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={()=>showModalChangePass()}>
                                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Change Password
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"  onClick={()=>checkLogout()}>
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </a>
                                    </div>
                                </div> */}

                                

                                <div className="nav-item dropdown no-arrow account-item clearfix">
                                    <div className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img className="img-profile rounded-circle mr-1" src="../images/user-profile.webp" style={{height: "50px"}}/>
                                        <span className="mr-2 d-none d-lg-inline text-black bold"><b>{localStorage.getItem('name')}</b></span>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <a className="dropdown-item" href="/user-profile">
                                            <i className="fa-solid fa-address-card mr-2 text-gray-400"></i>
                                            Profile
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={()=>showModalChangePass()}>
                                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Change Password
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"  onClick={()=>checkLogout()}>
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </a>
                                    </div>
                                </div>

                                {/* <div class="dropdown">
                                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Dropdown link
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div> */}

                            </div>

                  
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
    )
}

export default Header
