import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { format } from 'date-fns';
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';


function Blog() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    const [postList, setPostList] = useState([]);
    const getPostList = () => {
        axios.get(`https://api.vtalentsolution.com/api/getPosts`)
        .then((res) => {
            setPostList(res.data);
        });
    }
    const switchPost = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to change Post status?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchPost?postId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(()=>{
                            getPostList();
                            getPostsSearch();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [file, setFile] = useState(null);
    const [showImg, setShowImg] = useState('');
    const fileTypes = [
        'gif', 'jpeg', 'jpg', 'png', 'svg', 'jfif', 'tfif', 
        'tif', 'blob', 'webpimage', 'webp', 'raw', 'GIF', 
        'JPEG', 'JPG', 'PNG', 'SVG', 'JFIF', 'TFIF', 'TIF', 
        'BLOB', 'WEBPIMAGE', 'WEBP', 'RAW'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowImg(url);
    } 
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createPost = () => {
        setShow1(true);
    }
    const submitCreatePost = () => {
        if(title === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter Title",
            });
        }else if (content === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter article content",
            });
        } else if (file === null){
            Toast.fire({
                icon: "warning",
                title: "Add Image for post",
            });
        }else {
            const data = new FormData();
            data.append('title', title);
            data.append('content', content);
            data.append('image', file);
            axios({
                method: 'post',
                url: `https://api.vtalentsolution.com/api/createPost`,
                data: data
            })
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Created Successfully",
                    }).then(() => {
                        setShow1(false);
                        setContent('');
                        setTitle('');
                        getPostList();
                        getPostsSearch();
                    });
                } else if (res.data.msg.title) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.title,
                    });
                } else if (res.data.msg.content) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.content,
                    });
                } else if (res.data.msg) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editPost = (i, t, c) => {
        setShow2(true);
        setId(i);
        setTitle(t);
        setContent(c);
    }
    const submitEditPost = async () => {
        if(title === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter Title",
            });
        }else if (content === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter article content",
            });
        } else {
            const data = new URLSearchParams();
            data.append('postId', id);
            data.append('title', title);
            data.append('content', content);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editPost",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow2(false);
                        setContent('');
                        setTitle('');
                        getPostList();
                        getPostsSearch();
                    });
                } else if (result.msg.postId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.postId,
                    });
                } else if (result.msg.title) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.title,
                    });
                } else if (result.msg.content) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.content,
                    });
                }
            })
        }
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const changeImageTitle = (i, image) => {
        setShow3(true);
        setId(i);
        setShowImg(`https://api.vtalentsolution.com/images/`+image+``)
    }
    const submitChangeImageTitle = async () => {
        if(file === null){
            Toast.fire({
                icon: "warning",
                title: "Add Image for Post",
            });
        }else {
            const data = new FormData();
            data.append('postId', id);
            data.append('image', file);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/changeImageTitle",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow3(false);
                        getPostList();
                        getPostsSearch();
                    });
                } else if (result.msg.postId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.postId,
                    });
                } else if (result.msg) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg,
                    });
                }
            })
        }
    }
    const delPost = async (i) => {
        Swal.fire({
            title: "Do you want to delete this Post?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("postId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deletePost",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            getPostList();
                            getPostsSearch();
                        });
                    } else if (result.msg.postId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.postId,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [searchPosts, setSearchPosts] = useState([]);
    const getPostsSearch = async (e) => {
        await axios.get(`https://api.vtalentsolution.com/api/searchPosts?search=`+e+``)
        .then((res)=>{
            setSearchPosts(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getPostList()
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">POSTS LIST</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={createPost}>
                                            <i className="zmdi zmdi-plus mt-2"></i>add POST
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                <div className="col-lg-6 col-12">
                                    <input type="text" className="form-control border small" placeholder="Search for..." 
                                    aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getPostsSearch(e.target.value)}/>
                                </div>
                                {
                                    searchPosts.length>0 &&
                                    <div className="col-12 mt-4">
                                        <h6>Search results: {searchPosts.length}</h6>
                                    </div>
                                }
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    postList && postList.length > 0 && searchPosts.length <= 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>Image</th>
                                                    <th>Title</th>
                                                    <th>Status</th>
                                                    <th>Created At</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                postList.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'  style={{ cursor: "pointer" }} onClick={()=>changeImageTitle(item.id, item.imageTitle)}>
                                                        <img src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} style={{height: '50px'}}/>
                                                    </td>
                                                    <td className='align-middle'>{item.title}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPost(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{format(item.created_at, 'do MMMM  yyyy')}</td>
                                                    <td className='align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPost(item.id, item.title, item.content)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPost(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {
                                    searchPosts.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>Image</th>
                                                    <th>Title</th>
                                                    <th>Status</th>
                                                    <th>Created At</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                searchPosts.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'  style={{ cursor: "pointer" }} onClick={()=>changeImageTitle(item.id, item.imageTitle)}>
                                                        <img src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} style={{height: '50px'}}/>
                                                    </td>
                                                    <td className='align-middle'>{item.title}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPost(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{format(item.created_at, 'do MMMM  yyyy')}</td>
                                                    <td className='align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPost(item.id, item.title, item.content)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPost(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                               
                                </div>                              
                                
                            </div>
                            
                            
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Create Industry */}
        <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create New Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type="text" className="form-control" placeholder="image for post" onChange={(e) => setTitle(e.target.value)}/>
                <div className="col-12 mt-2">
                    <h6>Image For Post<sub></sub></h6>
                    <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                </div>
                <div className="col-12 mt-1">
                {
                    showImg !== '' &&
                    <img src={showImg} alt="" style={{ 'height':'120px', 'width' : 'auto'}}/>
                }
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="" className='mb-1'><b>Article content</b></label>
                    <ReactQuill
                        theme='snow'
                        multiple = {false}
                        onChange={setContent}
                        modules={{ 
                            toolbar : [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                
                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                [{ 'direction': 'rtl' }],                         // text direction
                                
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['link', 'image'],
                                
                                ['clean']                                         // remove formatting button
                            ]
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreatePost}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Post */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input value={title} type="text" className="form-control" onChange={(e) => setTitle(e.target.value)}/>
                <div className="col-12 mt-4">
                    <label htmlFor="" className='mb-1'><b>Article content</b></label>
                    <ReactQuill
                        value={content}
                        theme='snow'
                        multiple = {false}
                        onChange={setContent}
                        modules={{ 
                            toolbar : [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                
                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                [{ 'direction': 'rtl' }],                         // text direction
                                
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['link', 'image'],
                                
                                ['clean']                                         // remove formatting button
                            ]
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditPost}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Post */}
        <Modal show={show3} onHide={handleClose3} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Select image for Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 mt-2">
                    <h6>Image For Post<sub></sub></h6>
                    <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                </div>
                <div className="col-12 mt-1">
                {
                    showImg !== '' &&
                    <img src={showImg} alt="" style={{ 'height':'120px', 'width' : 'auto'}}/>
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitChangeImageTitle}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Blog
