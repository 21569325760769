import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function DocumentTraning() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [listDoc, setListDoc] = useState([]);
    const getDocs = async () => {
        await axios.get('https://api.vtalentsolution.com/api/getDocs')
        .then((res)=>{
            setListDoc(res.data);
        })
    }
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.openxmlformats-officedocument.wordprocessingml.docume'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createDoc = () => {
        setShow1(true);
    }
    const submitCreateDoc = () => {
        if (file === null) {
            Toast.fire({
                icon: 'error',
                title: 'Select your file, Please !!!'
            });
        } else {
            var data = new FormData();            
            data.append('fileDoc', file);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createDoc",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Success'
                    }).then(()=>{
                        getDocs();
                        setShow1(false);
                    })
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                }
            })
        }
    }
    const switchStatus = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to hide this Document?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchDoc?docId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(()=>{
                            getDocs();
                        })
                    } else if (result.msg.docId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.docId,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const deleteDoc = (i) => {
        Swal.fire({
            title: "Do you want to delete this Documnent?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("docId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteDoc",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            getDocs();
                        });
                    } else if (result.msg.docId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.docId,
                        });
                    } 
                });
            } else if (result.isDenied) {
            }
        });
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getDocs();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">POSTS LIST</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={createDoc}>
                                            <i className="zmdi zmdi-plus mt-2"></i>add DOC
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    listDoc && listDoc.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>File Name</th>
                                                    <th>Status</th>
                                                    <th>Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                listDoc.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.file_name}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchStatus(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash" style={{ cursor:"pointer", color: "red" }} onClick={()=>deleteDoc(item.id)}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                </div>                              
                                
                            </div>
                            
                            
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Add Document */}
        <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create New Document Training</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                <div className="row  align-items-center g-2">
                    
                    <div className="col-md-12 control-group my-4">
                        <label htmlFor=""><b style={{ color: "black" }}>Add Document Training <span style={{color: "red"}}>*</span></b></label>
                        <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                    </div>
                    <div className="col-md-12 control-group mb-2">
                        {
                            showFile !== '' &&
                            // <img src={showFile} alt=" " style={{ 'height':'120px', 'width' : 'auto'}}/>
                            <h5>{file.name}</h5>
                        }
                    </div>
                </div>                 
                
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreateDoc}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default DocumentTraning
