import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';

function Partner() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    // const [number, setNumber]= useState([]);
    // const [page, setPage] = useState(1);
    const [partners, setPartners] = useState([]);
    const getPartners = () => {
        axios.get(`https://api.vtalentsolution.com/api/getPartners`)
        .then((res) => {
            setPartners(res.data);
            // const old = [];
            // for (let i = 1; i <= res.data.last_page; i++) {
            //     old.push(i);
            // }
            // setNumber(old);
        });
    }
    const switchPartner = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to change Partner status?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchPartner?partnerId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(()=>{
                            getPartners();
                            getSearchPartners();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [companyPartner, setCompanyPartner] = useState('');
    const [mst, setMST] = useState('');
    const [descriptionPartner, setDescriptionPartner] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [contractStatus, setContractStatus] = useState('');
    const formatPhoneNumber = /(0[2|3|5|7|8|9]+([0-9]{8}))\b/g;
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createPartner = () => {
        setShow1(true);
    }
    const submitCreatePartner = () => {
        if(companyPartner === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter Company Name",
            });
        } else if(phone != '' && !phone.match(formatPhoneNumber)){
            Toast.fire({
                icon: 'error',
                title: 'Phone number format is incorrect !!'
            })
        }else {
            var data = new URLSearchParams();
            data.append('companyPartner', companyPartner);
            data.append('mst', mst);
            data.append('description', descriptionPartner);
            data.append('address', address);
            data.append('phone', phone);
            data.append('contractStatus', contractStatus);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createPartner",
                data: data,
            })
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow1(false);
                        getPartners();
                        setContractStatus('');
                        getSearchPartners();
                    });
                } else if (res.data.msg.companyPartner) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.companyPartner,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editPartner = (i, a, b, c, d, e, f) => {
        setShow2(true);
        setId(i);
        setCompanyPartner(a);
        setMST(b);
        setDescriptionPartner(c);
        setAddress(d);
        setPhone(e);
        setContractStatus(f);
    }
    const submitEditPartner = async () => {
        if(companyPartner === ''){
            Toast.fire({
                icon: "warning",
                title: "Enter Company Name",
            });
        } else if(phone != '' && !phone.match(formatPhoneNumber)){
            Toast.fire({
                icon: 'error',
                title: 'Phone number format is incorrect !!'
            })
        }else {
            var data = new FormData();
            data.append('partnerId', id);
            data.append('companyPartner', companyPartner);
            data.append('mst', mst);
            data.append('description', descriptionPartner);
            data.append('address', address);
            data.append('phone', phone);
            data.append('contractStatus', contractStatus);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editPartner",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow2(false);
                        getPartners();
                        setId(0);
                        setCompanyPartner('');
                        setMST('');
                        setDescriptionPartner('');
                        setAddress('');
                        setPhone('');
                        setContractStatus('');
                        getSearchPartners();
                    });
                } else if (result.msg.partnerId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.partnerId,
                    });
                } 
            })
        }
    }
    const delPartner = async (i) => {
        Swal.fire({
            title: "Do you want to delete this Company?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("partnerId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deletePartner",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            getPartners();
                            getSearchPartners();
                        });
                    } else if (result.msg.partnerId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.partnerId,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [searchPartners, setSearchPartners] = useState([]);
    const getSearchPartners = (e) => { 
        axios.get(`https://api.vtalentsolution.com/api/getSearchPartners?search=`+e+``)
        .then((res)=>{
            setSearchPartners(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getPartners()
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">COMPANY PARTNER</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue" onClick={createPartner}>
                                            <i className="zmdi zmdi-plus mt-2"></i>add partner
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                <div className="col-lg-6 col-12">
                                    <input type="text" className="form-control border small" placeholder="Search for..." 
                                    aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getSearchPartners(e.target.value)}/>
                                </div>
                                {
                                    searchPartners.length>0 &&
                                    <div className="col-12 mt-4">
                                        <h6>Search results: {searchPartners.length}</h6>
                                    </div>
                                }
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">USERS</h2> */}
                                {
                                    partners && partners.length > 0 && searchPartners.length <=0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>Company</th>
                                                    <th>Tax code</th>
                                                    <th>Description</th>
                                                    <th>Address</th>
                                                    <th>Phone</th>
                                                    <th>Contract</th>
                                                    <th>Status</th>
                                                    <th colSpan={2} className="text-center">Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                partners.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.company_name}</td>
                                                    <td className='align-middle'>{item.mst}</td>
                                                    <td className='align-middle'>{item.description}</td>
                                                    <td className='align-middle'>{item.address}</td>
                                                    <td className='align-middle'>{item.phone}</td>
                                                    <td className='align-middle'>{item.contract_status}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPartner(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPartner(item.id, item.company_name, item.mst, item.description, item.address, item.phone, item.contract_status)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPartner(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {
                                    searchPartners.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40" >
                                        <table className="table table-borderless table-striped" >
                                        {/* table-earning */}
                                            <thead>
                                                <tr className="bg-dark text-white">
                                                    <th>Company</th>
                                                    <th>Tax code</th>
                                                    <th>Description</th>
                                                    <th>Address</th>
                                                    <th>Phone</th>
                                                    <th>Contract</th>
                                                    <th>Status</th>
                                                    <th colSpan={2} className="text-center">Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                            {
                                                searchPartners.map((item, index)=>(
                                                <tr key={index} >
                                                    <td className='align-middle'>{item.company_name}</td>
                                                    <td className='align-middle'>{item.mst}</td>
                                                    <td className='align-middle'>{item.description}</td>
                                                    <td className='align-middle'>{item.address}</td>
                                                    <td className='align-middle'>{item.phone}</td>
                                                    <td className='align-middle'>{item.contract_status}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPartner(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPartner(item.id, item.company_name, item.mst, item.description, item.address, item.phone, item.contract_status)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPartner(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>                              
                                
                            </div>
                            
                            
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Create Partner */}
        <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create New Company Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <input type="text" className="form-control" placeholder="Company Name" onChange={(e) => setCompanyPartner(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Tax code" onChange={(e) => setMST(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Address" onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                    <div className="col-12 mt-3">
                        <input type="text" className="form-control" placeholder="Description" onChange={(e) => setDescriptionPartner(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Phone" onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <select name="" id="" className="form-control" onChange={(e) => setContractStatus(e.target.value)}>
                            <option value={contractStatus}>{contractStatus}</option>
                            <option value="Not signed yet">Not signed yet</option>
                            <option value="Signed">Signed</option>
                            <option value="Cancel Contract">Cancel Contract</option>
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreatePartner}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Partner */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update company information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                <div className="col-12">
                    <input type="text" className="form-control" placeholder="Company Name" value={companyPartner} onChange={(e) => setCompanyPartner(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Tax Code" value={mst} onChange={(e) => setMST(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                </div>
                <div className="col-12 mt-3">
                    <input type="text" className="form-control" placeholder="Description" value={descriptionPartner} onChange={(e) => setDescriptionPartner(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <select name="" id="" className="form-control" onChange={(e) => setContractStatus(e.target.value)}>
                        <option value={contractStatus}>{contractStatus}</option>
                        <option value="Not signed yet">Not signed yet</option>
                        <option value="Signed">Signed</option>
                        <option value="Cancel Contract">Cancel Contract</option>
                    </select>
                </div>
            </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditPartner}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Partner
