import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import moment from 'moment';

function DetailJob() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [companyList, setCompanyList] = useState([]);
    const getCompanyList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getPartnersOn`)
        .then((res)=> {            
            setCompanyList(res.data);
        })
    }
    const [jobList, setJobList] = useState([]);
    const getJobList = async () => {
        await axios.get('https://api.vtalentsolution.com/api/getJobs')
        .then((res)=>{
            setJobList(res.data);
        })
    }
    const [job, setJob] = useState({});
    // const [requirementList, setRequirementList] = useState([{requirement: ""}]);
    const {id} = useParams ();
    const getSingleJob = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJob?id=`+id+``)
        .then((res)=>{
            setJob(res.data[0]);
            // setRequirementList(JSON.parse(res.data[0].job_requirement_list));
        })
    }
    console.log(job);
    
    const [jobName, setJobName] = useState('');
    const [salary, setSalary] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [location, setLocation] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [industryName, setIndustryName] = useState(0);
    const [jobDescription ,setJobDescription] = useState('');
    const [jobRequirements, setJobRequirements] = useState('');
    const [jobBenefits, setJobBenefits]= useState('');
    const [companyId, setCompanyId] = useState(null);
    const [companyPartner, setCompanyPartner] = useState('');
    // const [requirementLists, setRequirementLists] = useState([{requirement: ""}]);
    // const addModuleRequirement = () => {
    //     setRequirementList([...requirementList, {requirement: ""}]);
    // }
    // const addRequirement = (value, index) => {
    //     const list = [...requirementList];
    //     list[index].requirement = value;
    // }
    // const delRequirement = (i) => {
    //     const list = [...requirementList];
    //     list.splice(i,1);
    //     setRequirementList(list);
    // }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false)
    const editJob = async (e) => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJob?id=`+e+``)
        .then((res)=>{
            setShow1(true);
            setJobName(res.data[0].job_name);
            setSalary(res.data[0].salary);
            setLocationId(res.data[0].location_id);
            setLocation(res.data[0].location);
            setIndustryId(res.data[0].industry_id);
            setIndustryName(res.data[0].industryName);
            setJobDescription(res.data[0].job_description);
            setJobRequirements(res.data[0].job_requirements);
            setJobRequirements(res.data[0].job_requirements);
            setJobBenefits(res.data[0].job_benefits);
            setCompanyId(res.data[0].company_id);
            setCompanyPartner(res.data[0].company_name);
            // setRequirementList(JSON.parse(res.data[0].job_requirement_list));
        })
        // setShow1(true);
        // setJobName(job.job_name);
        // setSalary(job.salary);
        // setLocationId(job.location_id);
        // setLocation(job.location);
        // setIndustryId(job.industry_id);
        // setIndustryName(job.industryName);
        // setJobDescription(job.job_description);
        // setJobRequirements(job.job_requirements);
        // setRequirementLists(JSON.parse(job.job_requirement_list));
    }
    
    const submitEditJob = () => {
        // const requir = JSON.stringify(requirementList);
        if(jobName === '' && salary === '' && locationId === 0 && industryId === 0){
            Toast.fire({
                icon: "warning",
                title: "Please enter information !!",
            });
        } else {
            const data = new URLSearchParams();
            data.append('id', id);
            data.append('jobName', jobName);
            data.append('salary', salary);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            // data.append('requirementList', requir);
            data.append('jobRequirements', jobRequirements);
            data.append('jobDescription', jobDescription);
            data.append('jobBenefits', jobBenefits);
            data.append('companyId', companyId);
            axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/editJob',
                data: data
            }).then((res)=> {
                console.log(res.data);
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(()=>{
                        getSingleJob();
                        setShow1(false);
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.salary){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.salary,
                    });
                } else if (result.msg.locationId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.locationId,
                    });
                } else if (result.msg.industryId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.industryId,
                    });
                }
            })
        }
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getSingleJob();
            getJobList();
            getLocationList();
            getIndustryList();
            getCompanyList();
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h3 className="m-0 font-weight-bold text-black">Job Information</h3>

                                        <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-2 px-4 ml-3 text center"  onClick={()=>editJob(job.id)}>
                                            <i className="fa-solid fa-pen-to-square text-white" style={{ cursor:"pointer"}}></i> Edit Job
                                        </span>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                {/* <div className="container-fluid rounded" style={{marginBottom:"20px", background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../images/page-header.jpg), no-repeat center center", backgroundSize: "cover", minHeight:"300px"}}>
                                                </div> */}
                                            {
                                                job &&
                                                <div className="border rounded px-3" style={{minHeight: "100px"}}>
                                                    <h4 className="text-center mt-3 fs-3">
                                                        <b>{job.job_name}</b>
                                                        {/* <i className="fa-solid fa-pen-to-square ms-3" onClick={()=>editJobName()} style={{ cursor:"pointer", color: "grey" }}></i> */}
                                                    </h4>
                                                    <h5 className="text-center mt-3">
                                                        {job.company_name}
                                                    </h5>
                                                    <hr className="my-4 mx-2"/>
                                                    <h3 className="fw-bold mb-4">Job Details</h3>
                                                    <p className="fs-5"><b className="me-5">Salary:</b> {job.salary}</p>
                                                    <p className="fs-5"><b className="me-5">Industry:</b> {job.industryName}</p>
                                                    <p className="fs-5"><b className="me-5">Location:</b> {job.location}</p>

                                                    <hr className="my-3 mx-2"/>
                                                    <h3 className="fw-bold mb-4">Job Description</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: job.job_description }}></div>

                                                    <hr className="my-3 mx-2"/>
                                                    <h3 className="fw-bold mb-4">Job Requirements</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: job.job_requirements }}></div>

                                                    <hr className="my-3 mx-2"/>
                                                    <h3 className="fw-bold mb-4">Benefits</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: job.job_benefits }}></div>

                                                    <hr className="my-3 mx-2"/>
                                                    {/* <h3 className="fw-bold mb-4">Danh sách yêu cầu chính cho công việc</h3>
                                                    <div className="row text-center">
                                                    {
                                                        requirementList && requirementList.length > 0 && requirementList.map((item, index)=> (
                                                        <div className="col-3 align-item-start" key={index}>
                                                            <p className="px-2 py-2 bg-warning rounded-pill" key={index}>
                                                                <b>{item.requirement}</b>
                                                            </p>
                                                        </div>
                                                        ))
                                                    }  
                                                    </div> */}
                                                    
                                                    <div className="text-center">
                                                        <button className="btn btn-primary btn-lg my-5 px-4" onClick={()=>editJob(job.id)}><i className="fa-solid fa-pen-to-square mx-3"></i>Edit Job</button>
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                            {/* <div className="col-md-12 border rounded" style={{ boxShadow: "-2px -4px 1px rgba(0, 0, 0, 0.7)" }}>
                                                <h3 className="mt-3 text-center"><b>Job List</b></h3>
                                                <div className="border rounded" style={{maxHeight: "1000px", overflowY: "auto"}}>
                                                {
                                                    jobList && jobList.length > 0 && jobList.map((item, index)=>(
                                                    <div className="p-4 mb-2 border rounded"  key={index}>
                                                        <a href={"/detail-job/"+item.id} className="text-decoration-none"><h5>{item.job_name}</h5></a>
                                                        <div className="pt-2 mt-1">
                                                            <h6 className="m-0 mt-2"><i className="fa fa-solid fa-money-bill text-primary mr-2"></i>Salary : <small>{item.salary}</small></h6>
                                                            <h6 className="m-0 mt-2"><i className="fa fa-solid fa-location-dot text-primary mr-2"></i>Location : <small>{item.location}</small></h6>
                                                            <h6 className="m-0 mt-2"><i className="fa fa-solid fa-briefcase text-primary mr-2"></i>Industry : <small>{item.industry}</small></h6>
                                                            <h6 className="m-0 mt-2"><i className="fa fa-solid fa-clock text-primary mr-2"></i>Update : <small>{item.created_at}</small></h6>
                                                        </div>
                                                    </div>
                                                    ))
                                                }
                                                </div>
                                            </div> */}
                                        </div>  
                                    </div>
                                </div>
                            </div>

                            <div className="clo-12 col-md-8">
                                <div className="card shadow mb-4">
                                    <h3 className="mt-3 text-center"><b>Job List</b></h3>
                                    <div className="border rounded" style={{maxHeight: "1000px", overflowY: "auto"}}>
                                    {/* {
                                        jobList && jobList.length > 0 && jobList.map((item, index)=>(
                                        <div className="p-4 mb-2  border rounded"  key={index}>
                                            <a href={"/detail-job/"+item.id} className="p-4 mb-2 bg-info text-white border rounded text-decoration-none d-block">
                                                <h5>{item.job_name}</h5>
                                                <div className="pt-2 mt-1">
                                                    <h6 className="m-0 mt-2"><i className="fa fa-solid fa-money-bill text-primary mr-2"></i>Salary : <small>{item.salary}</small></h6>
                                                    <h6 className="m-0 mt-2"><i className="fa fa-solid fa-location-dot text-primary mr-2"></i>Location : <small>{item.location}</small></h6>
                                                    <h6 className="m-0 mt-2"><i className="fa fa-solid fa-briefcase text-primary mr-2"></i>Industry : <small>{item.industry}</small></h6>
                                                    <h6 className="m-0 mt-2"><i className="fa fa-solid fa-clock text-primary mr-2"></i>Update : <small>{item.created_at}</small></h6>
                                                </div>
                                            </a>
                                        </div>
                                        ))
                                    } */}
                                    {
                                        jobList && jobList.length > 0 && jobList.map((item, index)=>(
                                        <a href={"/detail-job/"+item.id} className="p-4 mb-2 bg-warning border rounded text-decoration-none d-block"  key={index}>
                                            <h5>{item.job_name}</h5>
                                            <div className="pt-2 mt-1">
                                                <h6 className="m-0 mt-2"><i className="fa fa-solid fa-money-bill mr-2"></i>Salary : <small>{item.salary}</small></h6>
                                                <h6 className="m-0 mt-2"><i className="fa fa-solid fa-location-dot mr-2"></i>Location : <small>{item.location}</small></h6>
                                                <h6 className="m-0 mt-2"><i className="fa fa-solid fa-briefcase mr-2"></i>Industry : <small>{item.industry}</small></h6>
                                                <h6 className="m-0 mt-2"><i className="fa fa-solid fa-clock mr-2"></i>Update : <small>{moment(item.created_at).format('Do MMMM YYYY, h:mm:ss A')}</small></h6>
                                            </div>
                                        </a>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                                        
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Modal Edit Job  */}
        <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row  align-items-center g-2">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Job Name <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={jobName} onChange={(e) => setJobName(e.target.value)} placeholder="Job Name"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Salary <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={salary} onChange={(e) => setSalary(e.target.value)} placeholder="Salary"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Location <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                            {/* <option value="0">Chọn địa điểm công việc</option> */}
                            <option value={locationId}>{location}</option>
                        {
                            locationList && locationList.length > 0 && locationList.map((item, index) => (
                                <option key={index} value={item.id}>{item.location}</option>
                            ))
                        } 
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Industry <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                            <option value={industryId}>{industryName}</option>
                        {
                            industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.industry}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 col-md-12 mt-4">
                        <label htmlFor="nameCourse"><b>Customer : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setCompanyId(e.target.value)}>
                            <option value={companyId}>{companyPartner}</option>
                        {
                            companyList && companyList.length > 0 && companyList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.company_name}</option>
                            ))
                        }
                        </select>
                    </div>
                    <div className="col-12 mt-4">
                        <label htmlFor="" className='mb-1'><b>Job Description</b></label>
                        <ReactQuill
                            value={jobDescription}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobDescription}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Job Requirement</b></label>
                        <ReactQuill
                            value={jobRequirements}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobRequirements}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Benefits</b></label>
                        <ReactQuill
                            value={jobBenefits}
                            theme='snow'
                            multiple = {false}
                            onChange={setJobBenefits}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                    {/* {
                        requirementList && requirementList.length > 0  && requirementList.map((item, index) => (
                            <div className="col-10 col-md-8 my-2 me-2" key={index}>
                                <label htmlFor="requirement"><b>Yêu cầu {index + 1}</b></label>
                                <div className="d-flex flex-row">
                                    <input type="text" id="requirement" className='form-control mt-1' value={item.requirement}  onChange={(e) => addRequirement(e.target.value, index)} placeholder="Yêu cầu công việc"/>
                                    <button className="btn btn-danger ms-2" onClick={()=>delRequirement(index)}><i className="fa-solid fa-trash"></i></button>
                                </div>
                            </div>
                        ))
                    } */}
                    
                </div>
                {/* <button className="btn btn-primary mt-2 me-2" onClick={addModuleRequirement}>Thêm yêu cầu</button> */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditJob}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default DetailJob
