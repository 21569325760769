import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "../Components/Spinner";

function Login() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }                                    
    });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const checkLogin = () => {
        if(email === '' || password === ''){
            Toast.fire({
                icon: "error",
                title: "Please enter information !"
            });
        }else{
            console.log( email, password);
            var data = new FormData();
            data.append('email', email);
            data.append('password', password);
            axios(
                `https://api.vtalentsolution.com/api/checkLogin?email=`+email+`&password=`+password+``
            ).then((res) => {
                if(res.data.check == true){
                    Toast.fire({
                        icon: "success",
                        title: "Success"
                    }).then(()=>{
                        localStorage.setItem('email',email);
                        localStorage.setItem('id',res.data.result[0].id);
                        localStorage.setItem('role',res.data.result[0].role_id);
                        localStorage.setItem('name', res.data.result[0].name);
                        localStorage.setItem('token',res.data.result[0].remember_token);
                        window.location.replace('/dashboard');
                    })
                }else if (res.data.msg.email) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.email,
                    });
                }else if (res.data.msg.password) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.password,
                    });
                }else if (res.data.msg) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg,
                    });
                }
            });
        }
    }
    
    const [show5, setShow5] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const resetPassword = () => setShow(true);
    const submitResetPassword = (e) => {
        e.preventDefault();
        if(email === ''){
            Toast.fire({
                icon: "error",
                title: "Enter Your Email"
            });
        }else{
            setShow5(true);
            axios.post(`https://api.vtalentsolution.com/api/resetPassword?email=`+email+``)
            .then((res) => {
                if(res.data.check === true){
                    setShow5(false);
                    Toast.fire({
                        icon: "success",
                        title: "Please check your email to get a new password!"
                    }).then(()=>{
                        setShow(false);
                    })
                }else if (res.data.msg.email) {
                    setShow5(false);
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.email,
                    })
                }else if (res.data.msg) {
                    setShow5(false);
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg,
                    })
                }
            })
        }
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            window.location.replace('/dashboard');
        }
    },[]);
    return (
    <>
        {
        show5 &&
        <Spinner/>
    }
    <div className="page-wrapper">
        <div className="page-content--bge5">
            <div className="container">
                <div className="login-wrap">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="/login">
                                <img src="../images/vtalent logo.png" alt="Vtalent Solution" />
                            </a>
                        </div>
                        <div className="login-form">
                            <div className="form-group">
                                <label>Email Address</label>
                                <input className="au-input au-input--full" type="email" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input className="au-input au-input--full" type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            
                            <div className="login-checkbox">
                                {/* <label>
                                    <input type="checkbox" name="remember"/>Remember Me
                                </label> */}
                                <label>
                                    <a href="#" onClick={resetPassword}>Forgotten Password?</a>
                                </label>
                            </div>
                            <button className="au-btn au-btn--block au-btn--green m-b-20" onClick={()=>checkLogin()}>sign in</button>
                            <div className="social-login-content">
                                <div className="social-button">
                                    <button className="au-btn au-btn--block au-btn--blue m-b-20">Facebook Vtalent Solution</button>
                                    <button className="au-btn au-btn--block au-btn--blue2 ">Twitter Vtalent Solution</button>
                                </div>
                            </div>
                            {/* <div className="register-link">
                                <p>
                                    Don't you have account?
                                    <a href="#">Sign Up Here</a>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    {/* Modal Forgot Password */}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>PASSWORD RESET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="login-logo">
                <a href="/login">
                    <img src="../images/vtalent logo.png" alt="Vtalent Solution" />
                </a>
            </div>
            {/* <div classNameName="row justify-content-center align-items-center">
                <div classNameName="col-12 col-md-8 text-center mt-2">
                    Enter your email address and we'll 
                    send you an email with instructions 
                    to reset your password
                </div>
                <div classNameName="col-12 col-md-8 text-center mt-4">
                    <input
                        type="email"
                        placeholder='Email'
                        classNameName="au-input au-input--full"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                
                <div classNameName="col-12 col-md-8 text-center mt-4">
                    <button classNameName='btn btn-primary w-100' onClick={submitResetPassword}>Reset Password</button>
                </div>
            </div> */}
                <div className="login-form">
                        <div classNameName="form-group text-center mt-2">
                            Enter your email address and we'll 
                            send you an email with instructions 
                            to reset your password
                        </div>
                        <div className="form-group mt-4">
                            <label>Email Address</label>
                            <input className="au-input au-input--full" type="email" name="email" placeholder="Email"/>
                        </div>
                        <button className="au-btn au-btn--block au-btn--green m-b-20" onClick={submitResetPassword}>Reset Password</button>
                </div>
            
            
        </Modal.Body>
    </Modal>
    </>
    )
}

export default Login
