import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import axios from "axios";
import { format } from 'date-fns';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';
function Permission() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [role, setRole] = useState([]);
    const [id, setId] = useState(0);
    const getRole = () => {
        axios.get("https://api.vtalentsolution.com/api/getRoles")
        .then((res) => {
            setRole(res.data);
        });
    };
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const showModalCreateRole = (e) => {
        setShow1(true);
    };
    const submitCreateRole = async (e) => {
        e.preventDefault();
        if (!roleName || roleName === "") {
            Toast.fire({
                icon: "warning",
                title: "Enter permission",
            });
        } else {
            const data = new URLSearchParams();
            data.append("name", roleName);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createRole",
                data: data,
            }).then((res) => {
                const result = res.data;
                if (result.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(() => {
                        setShow1(false);
                        getRole();
                    });
                } else if (result.msg.name) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.name,
                    });
                }
            });
        }
    };

    const [show, setShow] = useState(false);
    const [roleName, setRoleName] = useState("");
    const handleClose = () => setShow(false);
    const showModalEditRole = (id, roleOld) => {
        setShow(true);
        setId(id);
        setRoleName(roleOld);
    };
    const submitEditRole = async (e) => {
        e.preventDefault();
        var data = new URLSearchParams();
        data.append("id", id);
        data.append("name", roleName);
        const res = await fetch("https://api.vtalentsolution.com/api/editRole", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data,
        });
        const result = await res.json();
        if (result.check === true) {
            Toast.fire({
                icon: "success",
                title: "Updated successfully",
            }).then(() => {
                setShow(false);
                getRole();
            });
        } else if (result.msg.id) {
            Toast.fire({
                icon: "error",
                title: result.msg.id,
            });
        } else if (result.msg.name) {
            Toast.fire({
                icon: "error",
                title: result.msg.name,
            });
        } else if (result.msg) {
            Toast.fire({
                icon: "error",
                title: result.msg,
            });
        }
    };
    const switchRole = (id) => {
        Swal.fire({
            title: "Do you want to change this Status?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", id);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/switchRole",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Success",
                        }).then(() => {
                            getRole();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    };
    const deleteRole = (id) => {
        Swal.fire({
            title: "Do you want to delete this User?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", id);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteRole",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted successfully",
                        }).then(() => {
                            getRole();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    };
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token') && localStorage.getItem('role') == 4){
            getRole();
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="overview-wrap">
                                        <h2 className="title-1 mt-2">MANAGER PERMISSION</h2>
                                        <button className="au-btn au-btn-icon au-btn--blue mt-2" onClick={showModalCreateRole}>
                                            <i className="zmdi zmdi-plus"></i>add role</button>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {/* <h2 className="title-1 m-b-25">MANAGER</h2> */}
                                {
                                    role && role.length > 0 &&
                                    <div className="table-responsive table--no-card m-b-40">
                                        <table className="table table-borderless table-striped table-earning">
                                            <thead>
                                                <tr>
                                                    <th>Permission</th>
                                                    <th>Status</th>
                                                    <th>Create At</th>
                                                    <th className="text-center">Custom</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                role.map((item, index)=>(
                                                <tr key={index}>
                                                    <td onClick={() => showModalEditRole(item.id, item.name)} style={{ cursor: "pointer" }}><b>{item.name}</b></td>
                                                    <td onClick={() => switchRole(item.id)}  style={{ cursor: "pointer" }}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td>{format(item.created_at, 'do MMMM  yyyy, h:mm:ss a')}</td>
                                                    <td className="text-center">
                                                        <span className='mx-3 my-1 fs-4 mr-2'><i className="fa-solid fa-pen-to-square" onClick={() => showModalEditRole(item.id, item.name)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={() => deleteRole(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>

                                                </tr>
                                                ))
                                            }
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                
                            </div>
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>

        </div>

         {/* Modal Edit Role */}
         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={roleName}
                    type="text"
                    id="editRole"
                    className="form-control"
                    placeholder="Tên Loại tài khoản"
                    onChange={(e) => setRoleName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditRole}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal Create Role */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    id="nameRole"
                    className="form-control"
                    placeholder="Tên Loại tài khoản"
                    onChange={(e) => setRoleName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitCreateRole}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Permission
