import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import HeaderMobile from '../Components/HeaderMobile';
import SpinnerS from '../Components/SpinnerS';
import Spinner from "../Components/Spinner";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { format } from 'date-fns';
// import { Spinner } from "react-bootstrap";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { set } from "date-fns";
import moment from 'moment';

function DetailCV() {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const {id} = useParams();
    const [cv, setCv] = useState({});
    const getSingleCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleCv?id=`+id+``)
        .then((res)=>{
            setCv(res.data[0]);
            setFullName(res.data[0].fullname);
            setEmail(res.data[0].email);
            setPhone(res.data[0].phone);
            setRecentJob(res.data[0].recent_job);
            setIndustryId(res.data[0].industryId);
            setIndustryName(res.data[0].industryName);
            setLocationId(res.data[0].locationId);
            setLocation(res.data[0].location);
            setNote(res.data[0].note);
        })
    }
    console.log(cv, cv.value_cv);
    
    
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [cvStatusNot1, setCvStatusNot1] = useState([]);
    const getCvStatusNot1 = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatusNot1`)
        .then((res)=>{
            setCvStatusNot1(res.data);
        })
    }
    
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [status, setStatus] = useState(0);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const changeStatus = () => {
        setStatus(cv.status)
        setShow1(true);
    }
    const submitChangeStatus = async () => {
        const userId = localStorage.getItem('id');
        const data = new FormData();
        data.append('id', id);
        data.append('statusId', status);
        data.append('userId', userId);
        await axios({
            method: 'post',
            url: 'https://api.vtalentsolution.com/api/changeStatus',
            data: data,
        }).then((res)=> {
            const result = res.data;
            if(result.check === true){
                Toast.fire({
                    icon: "success",
                    title: "Success",
                }).then(()=>{
                    getSingleCv();
                    setShow1(false);
                })
            } else if (result.msg.id){
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            } else if (result.msg.statusId){
                Toast.fire({
                    icon: "error",
                    title: result.msg.statusId,
                });
            }
        })
    }
    const saveNewFile = () => {
        if(file === null){
            Toast.fire({
                icon: "error",
                title: "Add File CV",
            })
        }else{
            const data = new FormData();
            data.append('id',id);
            data.append('newFileCv',file);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/addNewFileCv",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Success",
                    }).then(()=>{
                        getSingleCv();
                    })
                } else if (result.msg){
                    Toast.fire({
                        icon: "error",
                        title: result.msg,
                    });
                } else if (result.msg.id){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.id,
                    });
                }
            })
        }
    }
    const [cvList, setCvList] = useState([]);
    const getCvListWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCVsWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            setCvList(res.data);
        })
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const showModalEditCV = ()=>{
        setShow2(true);
    } 
    // const submitAddNote = () => { 
    //     // console.log(note);
    //     // console.log(id);  
    //     if(note === undefined){
    //         Toast.fire({
    //             icon: "warning",
    //             title: "Nhập nội dung",
    //         })
    //     } else {
    //         const data = new URLSearchParams();
    //         data.append('cvId', id);
    //         data.append('note', note);
    //         axios({
    //             method: 'post',
    //             url: 'https://api.vtalentsolution.com/api/addNote',
    //             data: data
    //         })
    //         // axios.post(`https://api.vtalentsolution.com/api/addNote?cvId=`+id+`&note=`+note+``)
    //         .then((res)=>{
    //             const result = res.data;
    //             if(result.check === true){
    //                 Toast.fire({
    //                     icon: "success",
    //                     title: "Đã thêm ghi chú thành công",
    //                 }).then(()=>{
    //                     getSingleCv();
    //                     setShow2(false);
    //                 })
    //             } else if (result.msg.cvId){
    //                 Toast.fire({
    //                     icon: "error",
    //                     title: result.msg.cvId,
    //                 });
    //             } else if (result.msg.note){
    //                 Toast.fire({
    //                     icon: "error",
    //                     title: result.msg.note,
    //                 });
    //             }
    //         })
    //     }
    // }

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [recentJob, setRecentJob] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [note, setNote] = useState('');
    const [location, setLocation] = useState('');
    const [industryName, setIndustryName] = useState('');
    const formatPhoneNumber = /(0[3|5|7|8|9]+([0-9]{8}))\b/g;  
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }

    const submitEditCv = () => {
        if(
            fullName === '' && email === '' && phone === ''){
            Toast.fire({
                icon: 'error',
                title: 'Please enter information !!'
            });
        } else if(!phone.match(formatPhoneNumber)) {
            Toast.fire({
                icon: 'error',
                title: 'Phone number format is incorrect !!'
            });
        } else {
            var data = new FormData();
            data.append('cvId', id);
            data.append('fullName', fullName);
            data.append('email', email);
            data.append('phone', phone);
            data.append('recentJob', recentJob);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('note', note);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editCv",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Success'
                    }).then(()=>{
                        setShow2(false);
                        getSingleCv();
                        setFullName('');
                        setEmail('');
                        setPhone('');
                        setRecentJob('');
                        setLocationId(0);
                        setIndustryId(0);
                        setNote('');
                        setIndustryName('');
                        setLocation('');
                    })
                }else if(res.data.msg.fullName){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.fullName
                    });
                }else if(res.data.msg.email){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.email
                    });
                }else if(res.data.msg.phone){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.phone
                    });
                }else if(res.data.msg.locationId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.locationId
                    });
                }else if(res.data.msg.industryId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.industryId
                    });
                }else if(res.data.msg.userId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.userId
                    });
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                }
            })
        }
    }

    const delCV = () => {
        Swal.fire({
            title: "Do you want to delete this CV?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("cvId", id);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteCV",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Deleted Successfully",
                        }).then(() => {
                            window.location.replace('/list-cv')
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }

    const [jobsSumCv, setJobsSumCv] = useState([]);
    const getJobsSumCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getJobsAndCV`)
        .then((res)=>{
            setJobsSumCv(res.data);
        })
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const openListJob = () => {
        setShow3(true);
    }
    const applyFor = (i) => {
        Swal.fire({
            icon:'question',
            text: "Do you want to apply CV for this Job?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/applyFor?cv_id=`+id+`&job_id=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Apply Successfully",
                        }).then(()=>{
                            setShow3(false);
                            getSingleCv();
                            getSingleJobCv();
                        })
                    } else if (result.msg.cv_id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.cv_id,
                        });
                    }else if (result.msg.job_id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.job_id,
                        });
                    }else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [listJobId, setListJobId] = useState([]);
    const getJobIdWitCvId = () => {
        axios.get(`https://api.vtalentsolution.com/api/getListJobWithCv?cvId=`+id+``)
        .then((res)=>{
            setListJobId(res.data);
        })
    }
    const [jobsSearch, setJobsSearch] = useState([]);
    const getJobsSearch = async (e) => {
        await axios.get(`https://api.vtalentsolution.com/api/getJobsWithKeyword?keyWord=`+e+``)
        .then((res)=>{
            setJobsSearch(res.data);
        })
    }
    const [singleJobCv, setSingleJobCv] = useState({});
    const getSingleJobCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJobCv?cv_id=`+id+``)
        .then((res)=>{
            setSingleJobCv(res.data);
        })
    }
    const [valueCv, setValueCv] = useState(0);
    const [multiplier, setMultiplier] = useState(1);
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const addValueCV = () => {
        setShow4(true);
        setValueCv(cv.value_cv);
        setMultiplier(cv.coefficient)
    }
    const submitAddValueCV = () => {
        if(
            valueCv === '' && multiplier === ''){
            Toast.fire({
                icon: 'error',
                title: 'Please enter information !!'
            });
        } else {
            var data = new FormData();
            data.append('cvId', id);
            data.append('valueCv', valueCv);
            data.append('coefficient', multiplier);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/addValueCV",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Success'
                    }).then(()=>{
                        setShow4(false);
                        setValueCv(0);
                        setMultiplier(1);
                        getSingleCv();
                    })
                }else if(res.data.msg.cvId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.cvId
                    });
                }else if(res.data.msg.valueCv){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.valueCv
                    });
                }else if(res.data.msg.coefficient){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.coefficient
                    });
                }
            })
        }
    }
        
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getSingleCv();
            getSingleJobCv();
            getCvStatus();
            getCvStatusNot1();
            getCvListWithUser();
            getJobsSumCv();
            getJobIdWitCvId();
            getJobsSearch();
            getIndustryList();
            getLocationList();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div className="page-wrapper">
            <HeaderMobile/>
            <Sidebar/>
            <div className='page-container'>
                <Header/>
                {/* <!-- MAIN CONTENT--> */}
                <div className="main-content">
                    <div className="section__content section__content--p30">
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h3 className="m-0 font-weight-bold text-black mt-2">
                                            CV Information
                                            
                                            
                                                
                                        </h3>
                                        {/* <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={()=>openListJob()}>
                                            <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                            Apply For
                                        </span> */}
                                        <div>
                                            <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-2 px-4 ml-3 text center mt-2"  onClick={()=>showModalEditCV()}>
                                                    <i className="fa-solid fa-pen-to-square text-white" style={{ cursor:"pointer"}}></i>
                                                </span>
                                            {
                                                localStorage.getItem('role') == 4 ?
                                                
                                                <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-2 px-4 ml-3 text center mt-2" >
                                                    <i className="ms-3 fa-solid fa-trash text-white" onClick={()=>delCV()} style={{ cursor:"pointer"}}></i>
                                                </span>
                                                :
                                                <span></span>
                                            }
                                            <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-2 px-4 ml-3 text center mt-2" onClick={()=>openListJob()}>
                                                <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                                Apply For
                                            </span>
                                        </div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-user text-dark mr-2"></i><b>Candidate Name:</b></p>
                                                <p className="text-secondary fw-bold">{cv.fullname}</p>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-envelope text-dark mr-2"></i><b>Email:</b></p>
                                                <p className="text-secondary fw-bold">{cv.email}</p>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-phone text-dark mr-2"></i><b>Phone:</b></p> 
                                                <p className="text-secondary fw-bold">{cv.phone}</p>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-circle-info text-dark mr-2"></i><b>Recent Job:</b> </p>
                                                <span className="text-secondary fw-bold">{cv.recent_job}</span>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-location-dot text-dark mr-2"></i><b>Desired workplace:</b></p>
                                                <p className="text-secondary fw-bold">{cv.location}</p>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-industry text-dark mr-2"></i><b className="me-3">Desired industry:</b></p>
                                                <p className="text-secondary fw-bold">{cv.industryName}</p>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p><i className="fa-solid fa-message text-dark mr-2"></i>
                                                    <b className="me-3">Message:</b>
                                                </p>
                                                <p className="text-secondary fw-bold">{cv.message}</p>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p>
                                                    <i className="fa-solid fa-message text-dark mr-2"></i>
                                                    <b className="me-3">Note:</b>
                                                    {/* <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square text-primary" onClick={()=>showModalNote(cv.note)}  style={{ cursor:"pointer"}}></i></span> */}
                                                </p>
                                                <p className="text-secondary fw-bold" dangerouslySetInnerHTML={{ __html: cv.note }}></p>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p>
                                                    <i className="fa-solid fa-file text-dark mr-2"></i><b className="mr-3">File CV:</b>
                                                    <a href={`https://api.vtalentsolution.com/FileCV/`+cv.file_cv+``} download={cv.file_cv}  target="_blank" className='mx-3 my-1 fs-4 primary'>
                                                        <i className="fa-solid fa-download"></i>
                                                    </a>

                                                </p>
                                                {/* <p>
                                                    <a href={`https://api.vtalentsolution.com/FileCV/`+cv.file_cv+``} target="_blank" className='mx-3 my-1 fs-4 primary'>
                                                        <b>{cv.file_cv}</b>
                                                    </a>
                                                </p> */}
                                                <p className="text-secondary fw-bold">{cv.file_cv}</p>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p>
                                                    <i className="fa-solid fa-paperclip text-dark mr-2"></i><b className="mr-3">New File CV:</b>
                                                {
                                                    cv.new_file_cv === null ?
                                                    ''
                                                    :
                                                    <a href={`https://api.vtalentsolution.com/FileCV/`+cv.new_file_cv+``} target="_blank" download={cv.new_file_cv} className='mx-3 my-1 fs-4 primary'>
                                                        <i className="fa-solid fa-download"></i>
                                                    </a>
                                                    // download={cv.new_file_cv}
                                                }
                                                </p>
                                                <p className="text-secondary fw-bold">{cv.new_file_cv}</p>
                                                <div className="mt-3">
                                                    <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false} className="mt-5"/>
                                                    {
                                                        showFile !== '' &&
                                                        // <h5>{file.name}</h5>
                                                        <div>
                                                            <b className="mr-3">{file.name}</b>
                                                            <a className='mx-3 my-1 fs-4' onClick={()=>saveNewFile()} style={{ cursor:"pointer"}}><i className="fa-solid fa-floppy-disk"></i></a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p><i className="fa-solid fa-circle-info text-dark mr-2"></i><b>Jobs are Applying:</b> </p>
                                                <div className="text-secondary fw-bold">
                                                    <p>Job Name: {singleJobCv.JobName}</p>
                                                    <p>Salary: {singleJobCv.Salary}</p> 
                                                    <p>Industry: {singleJobCv.industryName}</p> 
                                                    <p>Location: {singleJobCv.location}</p> 
                                                </div>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p>
                                                    <i className="fa-solid fa-flag text-dark mr-2"></i>
                                                    <b className="mr-3">CV Status:</b> 
                                                    {/* <a className='mx-3 my-1 fs-4' onClick={()=>changeStatus(cv.status)} style={{ cursor:"pointer"}}><i className="fa-solid fa-pen-to-square"></i></a> */}
                                                    <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-1 px-3 ml-3 text center" onClick={()=>changeStatus(cv.status)}>
                                                        <i className="fa-solid fa-pen-to-square text-white" style={{ cursor:"pointer"}}></i>
                                                    </span>
                                                </p>
                                            {/* {
                                                cv.status_name == 'Fisnish' ?
                                                <p className='align-middle text-success'><b>{cv.status_name}</b></p>
                                                : cv.status_name == 'Stop' ?
                                                <p className='align-middle text-danger'><b>{cv.status_name}</b></p>
                                                : cv.status_name == 'Not Start' ?
                                                <p className='align-middle text-primary'><b>{cv.status_name}</b></p>
                                                :
                                                <p className='align-middle text-warning'><b>{cv.status_name}</b></p>
                                            } */}
                                            {
                                                cv.status_name == 'Direct Applicant' ?
                                                <b className="text-primary">{cv.status_name}</b>
                                                : cv.status_name == 'Onboarded' ? 
                                                <b className="text-success">{cv.status_name}</b>
                                                : (cv.status_name == 'Stop' || cv.status_name == 'Drop Out' || cv.status_name == 'Withdrawn' || cv.status_name == 'Rejected by Account Holder' || cv.status_name == 'Rejected by Client' || cv.status_name == 'Offer Declined' || cv.status_name == 'Replaced') ?
                                                <b className="text-danger">{cv.status_name}</b>
                                                :
                                                <b className="text-warning">{cv.status_name}</b>
                                            }
                                                {/* <p className="text-secondary fw-bold">{cv.status_name}</p> */}
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-solid fa-calendar-days text-dark mr-2"></i><b className="mr-3">Created At:</b></p>
                                                <p className="text-secondary fw-bold">{moment(cv.created_at).format('MMMM Do YYYY, h:mm:ss A')}</p>
                                            </div>
                                            <div className="col-6 my-2">
                                                <p><i className="fa-regular fa-calendar-days text-dark mr-2"></i><b className="mr-3">Updated At:</b></p>
                                                <p className="text-secondary fw-bold">{moment(cv.updated_at).format('MMMM Do YYYY, h:mm:ss A')}</p>
                                            </div>
                                            <div className="col-12 my-2">
                                                <p>
                                                    <i className="fa-solid fa-money-bills text-dark mr-2"></i><b className="mr-3">Contract Value:</b>
                                                    {
                                                        (localStorage.getItem('role') >= 2 && cv.status_name == 'Onboarded') ?
                                                        <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-1 px-3 ml-3 text center" onClick={()=>addValueCV(cv.value_cv)}>
                                                            <i className="zmdi zmdi-money text-white" style={{ cursor:"pointer"}}></i>
                                                        </span>
                                                        :
                                                        <span></span>
                                                    }
                                                    {/* <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm py-1 px-3 ml-3 text center" onClick={()=>addValueCV(cv.value_cv)}>
                                                        <i className="zmdi zmdi-money text-white" style={{ cursor:"pointer"}}></i>
                                                    </span> */}
                                                </p>
                                                {/* {
                                                    cv.value_cv == null ?
                                                    <p className="text-black fw-bold px-2 px-4 py-2 bg-light w-50 mt-2 form-control"><b>NONE</b></p>
                                                    :
                                                    <p className="text-secondary fw-bold px-2 px-4"><b>{cv.value_cv} ĐỒNG</b></p>
                                                } */}
                                                <span className="text-black fw-bold px-2 px-4 py-2 bg-light w-50 mt-2 form-control"><b>{Intl.NumberFormat('en-US').format(cv.value_cv)}</b> <b>ĐỒNG</b></span> 
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="clo-12 col-md-8">
                                <div className="card shadow mb-4">
                                    {/* <!-- Card Header - Dropdown --> */}
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h3 className="m-0 font-weight-bold text-black">Processing</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="border rounded" style={{maxHeight: "1000px", overflowY: "auto"}}>
                                        {
                                            cvList && cvList.length > 0 && cvList.map((item, index)=>(
                                            <a href={"/detail-cv/"+item.id} className="p-4 mb-2 bg-warning border rounded text-decoration-none d-block"  key={index}>
                                                {/* <a href={"/detail-job/"+item.id} className="text-decoration-none"><h5>{item.job_name}</h5></a> */}
                                                <div className="mt-1">
                                                    <h6 className="m-0 mt-2"><i className="fa-regular fa-user mr-2"></i>{item.fullname}</h6>
                                                    <h6 className="m-0 mt-2"><i className="fa-solid fa-phone mr-2"></i>{item.phone}</h6>
                                                    <h6 className="m-0 mt-2"><i className="fa-regular fa-envelope mr-2"></i>{item.email}</h6>
                                                    <h6 className="m-0 mt-2"><i className="fa-solid fa-spinner mr-2"></i>{item.status_name}</h6>
                                                </div>
                                            </a>
                                            ))
                                        }
                                        {
                                            !cvList || cvList.length <= 0 &&
                                            <div>
                                                {
                                                    <SpinnerS/>
                                                }
                                            </div>
                                        }
                                        </div>
                                        {/* <div className="mt-4 text-center small">
                                            <span className="mr-2">
                                                <i className="fas fa-circle text-primary"></i> Direct
                                            </span>
                                            <span className="mr-2">
                                                <i className="fas fa-circle text-success"></i> Social
                                            </span>
                                            <span className="mr-2">
                                                <i className="fas fa-circle text-info"></i> Referral
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                                        
                            
                            
                            <Footer/>
                        </div>
                    </div>
                </div>
                {/* <!-- END MAIN CONTENT--> */}
            </div>
        </div>

        {/* Change Status Model */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Change CV Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <label htmlFor="status"><b>Select CV Status <span style={{color: "red"}}>*</span></b></label>
                    <select name="" id="status" className="form-control mt-1" onChange={(e)=> setStatus(e.target.value)}>
                        <option value={cv.status}>{cv.status_name}</option>
                    {
                        cvStatusNot1 && cvStatusNot1.length > 0 && cvStatusNot1.map((item, index)=> (
                            <option key={index} value={item.id}>{item.status_name}</option>
                        ))
                    }
                    </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitChangeStatus}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit CV */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update CV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                <div className="row  align-items-center g-2">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Candidate Name : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Candidate Name"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Email : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Phone : <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Recent Job :</b></label>
                        <input type="text" className='form-control mt-1' value={recentJob} onChange={(e) => setRecentJob(e.target.value)} placeholder="Recent Job"/>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Desired Workplace : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                        {
                            locationId == 0 || locationId == null?
                            <option value='0'>Select Location</option>
                            :
                            <option value={locationId}>{location}</option>
                        }
                            
                        {
                            locationList && locationList.length > 0 && locationList.map((item, index) => (
                                <option key={index} value={item.id}>{item.location}</option>
                            ))
                        } 
                        </select>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Industry : <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                            {
                            industryId == 0 || industryId == null?
                            <option value='0'>Select Industry</option>
                            :
                            <option value={industryId}>{industryName}</option>
                        }
                        {
                            industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                <option key={index} value={item.id}>{item.industry}</option>
                            ))
                        }
                        </select>
                    </div>
                    
                    <div className="col-12 mt-4 mb-4">
                        <label htmlFor="" className='mb-1'><b>Note</b></label>
                        <ReactQuill
                            value={note}
                            theme='snow'
                            multiple = {false}
                            onChange={setNote}
                            modules={{ 
                                toolbar : [
                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                    ['blockquote', 'code-block'],
                                    
                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                    [{ 'direction': 'rtl' }],                         // text direction
                                    
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    
                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image'],
                                    
                                    ['clean']                                         // remove formatting button
                                ]
                            }}
                        />
                    </div>
                </div>                    
                
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitEditCv}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Apply For */}
        <Modal show={show3} onHide={handleClose3} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Apply For Job</Modal.Title>
                
            </Modal.Header>
            <Modal.Body>
            <div className="mb-4 ml-3">
                <input type="text" className="form-control w-50" placeholder="search job name" onKeyUp={(e)=>getJobsSearch(e.target.value)}/>
            </div>
            <div className="card-body">
            {
                jobsSumCv && jobsSumCv.length > 0 && jobsSearch.length <= 0 &&
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                            <tr className='table-secondary text-center'>
                                <th className='align-middle'>Job Name</th>
                                <th className='align-middle'>Location</th>
                                <th className='align-middle'>Industry</th>
                                <th className='align-middle'>Salary</th>
                                <th className='align-middle'>Quantity CV Apply for</th>
                                <th className='align-middle'>Custom</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            jobsSumCv.map((item, index)=>(
                            <tr key={index} className="text-center">
                                <td className='align-middle'>{item.job_name}</td>
                                <td className='align-middle'>{item.location}</td>
                                <td className='align-middle'>{item.industryName}</td>
                                <td className='align-middle'>{item.salary}</td>
                                <td className='align-middle'>{item.SL}</td>
                                <td className='text-center align-middle'>
                                    {/* {
                                        (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                        <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                        :
                                        (localStorage.getItem('role') >=3) ?
                                        <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                        :
                                        <span></span>
                                    }
                                    {
                                        (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                        <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                        :
                                        <span></span>
                                    } */}
                                    {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                    {
                                        listJobId.includes(item.id) ?
                                        // <button className='btn btn-secondary btn-sm disabled'>Đã Apply</button>
                                        <span className='mx-1 my-1'>Applied</span>
                                        :
                                        <button className='btn btn-primary' onClick={()=>applyFor(item.id)}>Apply</button>
                                    }
                                </td>
                            </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            }               
            {
                jobsSearch && jobsSearch.length > 0 &&
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                        <thead>
                            <tr className='table-secondary text-center'>
                                <th className='align-middle'>Job Name</th>
                                <th className='align-middle'>Location</th>
                                <th className='align-middle'>Industry</th>
                                <th className='align-middle'>Salary</th>
                                <th className='align-middle'>Quantity CV Apply for</th>
                                <th className='align-middle'>Custom</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            jobsSearch.map((item, index)=>(
                            <tr key={index} className="text-center">
                                <td className='align-middle'>{item.job_name}</td>
                                <td className='align-middle'>{item.location}</td>
                                <td className='align-middle'>{item.industryName}</td>
                                <td className='align-middle'>{item.salary}</td>
                                <td className='align-middle'>{item.SL}</td>
                                <td className='text-center align-middle'>
                                    {/* {
                                        (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                        <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                        :
                                        (localStorage.getItem('role') >=3) ?
                                        <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                        :
                                        <span></span>
                                    }
                                    {
                                        (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                        <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                        :
                                        <span></span>
                                    } */}
                                    {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                    {
                                        listJobId.includes(item.id) ?
                                        // <button className='btn btn-secondary btn-sm disabled'>Đã Apply</button>
                                        <span className='mx-1 my-1'>Applied</span>
                                        :
                                        <button className='btn btn-primary' onClick={()=>applyFor(item.id)}>Apply</button>
                                    }
                                </td>
                            </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            } 
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={submitApplyFor}>
                    Lưu
                </Button> */}
            </Modal.Footer>
        </Modal>

        {/* Add Value CV */}
        <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
                <Modal.Title>Add Value CV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="nameCourse"><b>Contract Amount : <span style={{color: "red"}}>*</span></b></label>
                <input type="text" className='form-control mt-1' value={valueCv} onChange={(e) => setValueCv(e.target.value)} placeholder=""/>
                <label htmlFor="nameCourse"><b>Coefficient : <span style={{color: "red"}}>*</span></b></label>
                <input type="text" className='form-control mt-1' value={multiplier} onChange={(e) => setMultiplier(e.target.value)} placeholder=""/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose4}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitAddValueCV}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default DetailCV
